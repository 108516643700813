import { AppMessageModel } from "@/types/AppMessage"

/**
 * Representing the numeric values returned by the WebSocket as its ready state (Connecting = 0, Open = 1, etc.), with
 * an additional -1 value to mean NoSocket, which we use to record whether the socket has been instantiated yet.
 */
export enum ReadyState {
    Connecting,
    Open,
    Closing,
    Closed,
    NoSocket = -1,
}

/**
 * Any listener for "Bonza.socket" messages can expect at least this information.
 */
export interface SocketEvent {
    type: "open" | "close" | "error" | "message"
    event: Event | CloseEvent | MessageEvent
}

/**
 * Listeners for socket events need to implement this.
 */
export interface SocketEventListener {
    handleSocketEvent(event: SocketEvent): void
}

/**
 * Listeners for message events need to implement this.
 */
export interface MessageEventListener {
    handleMessageEvent(event: MessageEvent, data: any | null | undefined): void
}

export interface WebSocketService {
    connect: () => void
    disconnect: () => void
    addEventListener(listener: SocketEventListener): void
    removeEventListener(
        listener: SocketEventListener
    ): SocketEventListener | null
    addMessageListener(listener: MessageEventListener): void
    removeMessageListener(
        listener: MessageEventListener
    ): MessageEventListener | null

    /**
     * Sends a message using the given message object.
     *
     * @param {IAppMessage} message The message object to send.
     *
     * @return {void}
     */
    send(message: AppMessageModel): boolean
}
