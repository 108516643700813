import { IAgentService } from "@/services/AgentService"
import { WebSocketService } from "./WebSocket"
import { RemoteConnectState } from "./RemoteManager"

export interface PlayerViewGeneric {
    name: string
    img?: string
    agent: WebSocketService & IAgentService
    handleClose?: (name: string) => void
}

export type RTInfo = {
    latency?: string
    dropout?: string
    jitter?: string
}

export const RTInfoEmptyField = "."

export interface PlayerViewRemote extends PlayerViewGeneric {
    // remote
    ip?: string
    port?: string
    readiness?: RemoteConnectState
    info?: RTInfo
}

export interface PlayerViewLocal extends PlayerViewGeneric {
    // remote
    channel: number
}

// export interface PlayerView extends PlayerViewRemote {
//     // at pres exactly same..
// }
