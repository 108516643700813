import { SVGAttributes } from "react"

export interface VuBackgroundProps extends SVGAttributes<SVGElement> {
    /**
     * Providing the width of the background allows the component to correctly size the columns and dots.
     */
    width?: number
    /**
     * How many dots across will the VuMeter want?
     */
    columns?: number
    /**
     * If there is more than one column, there may be a gap required; in the original designs, for example, there
     * are four columns and the gapAt value would be 2
     */
    gapAt?: number
}

/**
 * Semi-intelligent generator for VuMeter background, which consists of columns of transparent dots through which
 * shine the volume (etc.) levels.
 */
export default function VuBackground(props: VuBackgroundProps) {
    const width = props.width ?? 40
    const columns = props.columns ?? 4
    const gapAt = props.gapAt ?? 2
    const fill = props.fill ?? "#131619"

    const colWidth = width / (gapAt < columns ? columns + 1 : columns)
    const colHalf = colWidth / 2
    const arcRad = colHalf * 0.9
    const arcGap = colHalf - arcRad

    const paths = []
    let x1 = 0
    let x2 = x1 + colWidth

    for (let i = 0; i < columns; i++) {
        if (i == gapAt) {
            x2 += colWidth
            paths.push(
                <path
                    key={i}
                    fill={fill}
                    d={`m${x1},0 H${x2} v${colWidth} H${x1} z m${arcRad + colWidth},${arcGap} a${arcRad},${arcRad} 1 1,0 1,0 z`}
                />
            )
            x1 += colWidth
        } else {
            paths.push(
                <path
                    key={i}
                    fill={fill}
                    d={`m${x1},0 H${x2} v${colWidth} H${x1} z m${arcRad},${arcGap} a${arcRad},${arcRad} 1 1,0 1,0 z`}
                />
            )
        }
        x1 += colWidth
        x2 += colWidth
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={colWidth}>
            {paths}
        </svg>
    )
}
