import { useEffect, useState } from "react"
import VuBackground, {
    VuBackgroundProps,
} from "@/components/parts/VuBackground"
import ReactDOMServer from "react-dom/server"
import { Color } from "@/types/Color"
import { WebSocketService } from "@/types/WebSocket"
import LoggerService from "@/services/LoggerService"
import { LR_Type } from "./AudioControlLocal"
import { AudioInitialisationSequenceStates, fNOP } from "@/types/Device"
import { LocalDevice } from "@/services/BonzaService"
import { isIP } from "@/types/RemoteManager"
import { amplitude2dBSPLMapToMinMax } from "./VuMeter"
export interface VuMeterDoubleProps extends VuBackgroundProps {
    lrtype: LR_Type
    channel?: number
    IP?: string
    portTCP?: string

    agent: WebSocketService
    value?: number
    loColor?: Color
    hiColor?: Color
    dangerColor?: Color
    dangerLevel?: number
    bgColor?: Color
    min?: number
    max?: number
    ticks?: boolean
    vals?: boolean
    columns?: number
    gapAt?: number
    invert?: boolean
    inactive?: boolean
}

export default function VuMeterDouble(props: VuMeterDoubleProps) {
    const columns = props.columns ?? 2
    const bg = VuBackground({
        width: 10 * columns,
        fill: props.bgColor ?? undefined,
        columns: columns,
        gapAt: props.gapAt ?? undefined,
    })
    const encoded = encodeURIComponent(ReactDOMServer.renderToString(bg))

    const min = props.min ?? 0
    const max = props.max ?? 10

    const hiColor = props.hiColor ?? "#455DFF"
    const loColor = props.loColor ?? "#707070"
    const dangerColor = props.dangerColor ?? "#f57d9d"
    const dangerLevel = props.dangerLevel ?? 19 / 23

    const [levelL, setLevelL] = useState(props.value ?? 0)
    const dangerL = levelL - dangerLevel
    const [levelR, setLevelR] = useState(props.value ?? 0)
    const dangerR = levelR - dangerLevel

    const agent = props.agent
    const channel = props.channel
    const IP = props.IP
    const portTCP = props.portTCP
    if (IP != undefined && isIP(IP)) {
        fNOP()
    } else {
        fNOP()
    }
    let logger: LoggerService
    if (props.lrtype == LR_Type.Local) {
        logger = new LoggerService(`VuMeterDouble for channel#${channel}`)
    }
    if (props.lrtype == LR_Type.Remote) {
        logger = new LoggerService(`VuMeterDouble for IP#${IP}:${portTCP}`)
    }

    /*
     * fakeMeterSignals is for test/demo purposes only
     */
    useEffect(() => {
        if (props.lrtype == LR_Type.Local) {
            logger.log(`I'm here for channel ${channel}`)
        }
        if (props.lrtype == LR_Type.Remote) {
            logger.log(`I'm here for IP ${IP}:${portTCP}`)
        }

        const agentMessageListener = {
            handleMessageEvent(event: MessageEvent) {
                const data = JSON.parse(event.data)
                // 240327 - setLocalSoundLevelV1 is NOT IMPLEMENTED in BA @ pres...
                // but we NEED it for being able to handle MASTER vol (chan -1) and for stereo say chan -2

                // or in BA since a new message needs to be designed, if chan is -1 we could supply 2 floats (L and R) - all TBD

                // and as some point, since each remote is stereo, we might want a stereo VuMeter per remote? (but again, there
                // is no BA message to do that (yet) )

                if (
                    data.type == "setLocalSoundLevelV1" &&
                    props.lrtype == LR_Type.Local
                ) {
                    const chanLevelF: number = data.level0 // 0.0 to 1.0
                    const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                        chanLevelF,
                        -20,
                        3,
                        min,
                        max
                    )
                    if (data.channel === -1) {
                        // master
                        fNOP()
                    }
                    if (data.channel === "-1") {
                        // master (old string way)
                        fNOP()
                    }
                    if (data.channel === channel) {
                        if (channel === -1) {
                            // master has 2 chans in one message
                            // "level0" (L) and "level1" (R)
                            setLevelL((chanlevelDisp * 100) / max)
                            const chanLevelFR: number = data.level1 // 0.0 to 1.0
                            const chanlevelDispR = amplitude2dBSPLMapToMinMax(
                                chanLevelFR,
                                -20,
                                3,
                                min,
                                max
                            )
                            setLevelR((chanlevelDispR * 100) / max)
                        } else {
                            setLevelL((chanlevelDisp * 100) / max)
                            setLevelR((chanlevelDisp * 100) / max)
                        }
                    }
                } else if (
                    data.type == "setLocalSoundLevel" &&
                    props.lrtype == LR_Type.Local
                ) {
                    // channelCount : string   // num chans (eg "2")
                    // maxSampleValue : string  // `;` delimited string of floats eg "0;0.5"
                    if (channel === -1) {
                        return // this message setLocalSoundLevel does not handle MASTER (-1)
                    }
                    const numChansInMsg: number = Number(data.channelCount)
                    if (typeof numChansInMsg != typeof 1) {
                        return
                    }
                    if (channel == null || channel >= numChansInMsg) {
                        // valid channels are 0:(MAX-1)
                        return
                    }
                    const maxSampleValuesStr: string = data.maxSampleValue
                    let chanLevels
                    if (maxSampleValuesStr && maxSampleValuesStr.length) {
                        chanLevels = maxSampleValuesStr.split(";")
                    } else {
                        return
                    }
                    if (chanLevels.length <= numChansInMsg) {
                        return
                    }
                    if (channel >= 0) {
                        const chanLevelF: number = Number(chanLevels[channel]) // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        // dB = 20 * log10(amplitude)
                        // vu meter should display -20 to +3dB (SPL)
                        // but std range on this meter is 0:100.

                        setLevelL((chanlevelDisp * 100) / max)
                        setLevelR((chanlevelDisp * 100) / max)
                    }
                } else if (
                    data.type == "setRemoteSoundLevel" &&
                    props.lrtype == LR_Type.Remote
                ) {
                    /*
                        data1, data2, data3, data4
                        ID,    value, IP,    port
                    */
                    if (data.data3 == IP && data.data4 == portTCP) {
                        const chanLevelF: number = Number(data.data2) // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        setLevelL((chanlevelDisp * 100) / max)
                        setLevelR((chanlevelDisp * 100) / max)
                    } else {
                        if (IP == undefined) {
                            fNOP()
                        } else {
                            if (!isIP(IP)) {
                                fNOP()
                            }
                        }
                    }
                }
            },
        }

        agent.addMessageListener(agentMessageListener)

        const fakeMeterSignals: boolean = false // test audio in
        const updatems: number = 200
        const x = setInterval(() => {
            if (fakeMeterSignals) {
                let v = Math.random()
                v = amplitude2dBSPLMapToMinMax(v, -20, 3, min, max)
                setLevelL(v * 10)
                v = Math.random()
                v = amplitude2dBSPLMapToMinMax(v, -20, 3, min, max)
                setLevelR(v * 10)
            }
        }, updatems)

        return () => {
            // clearInterval(x);
            agent.removeMessageListener(agentMessageListener)
        }
    }, [props.IP])

    const tickMarks = []
    const tickVals = []

    const ticks = props.ticks
    const vals = props.vals

    if (ticks || vals) {
        for (let i = max; i >= min; i--) {
            if (ticks) {
                tickMarks.push(
                    <p key={i} className="grow">
                        -
                    </p>
                )
            }
            if (vals) {
                tickVals.push(
                    <p key={i} className="grow">
                        {i}
                    </p>
                )
            }
        }
    }

    const ticksWidth = ticks ? 4 : 1
    const valsWidth = vals ? 4 : 1

    return (
        <div
            className={`
                ${props.className}

                flex flex-col
            `}
        >
            <div className={`flex flex-1 rounded-xl bg-bonza-dark px-5 py-8`}>
                <div className="flex h-[320px] flex-row space-x-2">
                    <div
                        className={`
                            w-${ticksWidth}

                            -my-3 flex flex-col pt-1
                        `}
                        style={{ color: loColor }}
                    >
                        {ticks ? tickMarks : <p className="grow">&nbsp;</p>}
                    </div>
                    <div
                        className={`
                            flex-1

                            w-${2.5 * columns}
                        `}
                        style={{
                            backgroundColor: `${loColor}`,
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: `${hiColor}`,
                                height: `${levelL}%`,
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                            }}
                        />
                        {dangerL > 0 ? (
                            <div
                                style={{
                                    backgroundColor: `${dangerColor}`,
                                    height: `${dangerL}%`,
                                    position: "absolute",
                                    bottom: `${dangerLevel}%`,
                                    width: "100%",
                                }}
                            />
                        ) : (
                            ""
                        )}
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                backgroundImage: `url("data:image/svg+xml;charset=UTF-8,${encoded}")`,
                            }}
                        />
                    </div>
                    <div
                        className={`
                            flex-1

                            w-${2.5 * columns}

                            ml-2
                        `}
                        style={{
                            backgroundColor: `${loColor}`,
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: `${hiColor}`,
                                height: `${levelR}%`,
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                            }}
                        />
                        {dangerR > 0 ? (
                            <div
                                style={{
                                    backgroundColor: `${dangerColor}`,
                                    height: `${dangerR}%`,
                                    position: "absolute",
                                    bottom: `${dangerLevel}%`,
                                    width: "100%",
                                }}
                            />
                        ) : (
                            ""
                        )}
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                backgroundImage: `url("data:image/svg+xml;charset=UTF-8,${encoded}")`,
                            }}
                        />
                    </div>
                    <div
                        className={`
                            w-${valsWidth}

                            -my-4 flex flex-col items-center pt-4 text-tiny
                        `}
                        style={{ color: loColor }}
                    >
                        {vals ? tickVals : <p className="grow">&nbsp;</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}
