import { InviteResponse } from "@/types/Invite"
import { getRequest } from "./helpers/AxiosHelper"
import { UserSearchResponse } from "@/types/User"
import { BonzaSessionResponse } from "@/types/BonzaSession"
import { UserModel } from "@/types/UserClass"

export const getPeopleSearch = async (search: string) => {
    const debRouteName = "api.user.search"
    const debRoute = route(debRouteName, { search })

    const res = await getRequest<UserSearchResponse[]>(debRoute, "User")

    return res
}

export const getUserInvites = async () => {
    const debRouteName = "api.user.invitations"
    const debRoute = route(debRouteName)

    const res = await getRequest<InviteResponse>(debRoute, "User")

    return res
}

export const getUserSessions = async () => {
    const debRouteName = "api.user.bonza_sessions"
    const debRoute = route(debRouteName)

    const res = await getRequest<BonzaSessionResponse[]>(debRoute, "User")

    return res
}

export const getUserList = async () => {
    const debRouteName = "api.user.list"
    const debRoute = route(debRouteName)

    const res = await getRequest<UserModel[]>(debRoute, "User")

    return res
}

