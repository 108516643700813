import { PlayerViewGeneric } from "@/types/PlayerView"
import { fNOP } from "@/types/Device"
import { LocalRemoteManager } from "@/services/BonzaService"
import { PanZoneModel } from "@/types/PanZone"

export type PanZoneAction = {
    player: PlayerViewGeneric
    pan?: number
    panZone?: PanZoneModel
    delete?: boolean
}

type PanZoneIndexAndOffset = {
    index: number
    offset: number
}

const getPanZoneIndexAndOffset = (
    pan: number,
    count: number,
    range: { min: number; max: number }
): PanZoneIndexAndOffset => {
    /*
     * Get from (e.g.) -100..+100 to 0..200 to make it easier to get the index
     */
    const norm = 0 - range.min
    const max = range.max + norm
    const width = max / count
    pan += norm

    return {
        index: Math.floor(pan / width) + 1,
        offset: pan % width,
    }
}

export const panZoneReducer = (
    panZones: PanZoneModel[],
    action: PanZoneAction
) => {
    const player = action.player
    if (action.pan !== undefined) {
        const pan: number = action.pan

        const indexAndOffset = getPanZoneIndexAndOffset(pan, panZones.length, {
            min: -100,
            max: +100,
        })
        //TODO - handle nuanced positioning for pan
    } else if (action.panZone !== undefined) {
        const toPanZone = action.panZone
        panZones.some((panZone) => {
            const p = panZone.players?.find((p) => p.name == player.name)
            const found = p !== undefined
            if (found) {
                const x = panZone.players?.indexOf(p)
                if (x > -1) {
                    //panZone.players = panZone.players.toSpliced(x, 1);
                    // KB 240524
                    panZone.players = panZone.players.filter((_, i) => i !== x)
                }
            }
            return found
        })

        if (!toPanZone.players) {
            toPanZone.players = [player]
        } else {
            toPanZone.players = [...toPanZone.players, player]
        }
        // KB 240419 - temp non-incremental ZBP
        if (LocalRemoteManager.useTempZBP) {
            LocalRemoteManager.tempZBPHandleChange(
                toPanZone,
                player,
                null,
                null,
                null
            )
        }
    } else if (action.delete) {
        /*
         * If player is present but no panZone, we'll remove.
         */
        panZones.some((panZone) => {
            const p = panZone.players?.find((p) => p.name == player.name)
            const found = p !== undefined
            if (found) {
                const x = panZone.players?.indexOf(p)
                if (x > -1) {
                    // panZone.players = panZone.players.toSpliced(x, 1);
                    // KB 240524
                    panZone.players = panZone.players.filter((_, i) => i !== x)
                }
            }
            return found
        })
    } else {
        // panZones.some(panZone => {
        //     const p = panZone.players?.find(p => p.name == player.name);
        //     const found = p !== undefined;
        //     if (found) {
        //         const x = panZone.players?.indexOf(p);
        //         if (x > -1) {
        //             panZone.players = [...panZone.players];//.toSpliced(x, 1), p];
        //         }
        //         //console.log(`Updating panZone ${panZone.name} for ${player.name}`)
        //     }
        //     return found;
        // })
    }

    return [...panZones]
}
