import { RTInfo, RTInfoEmptyField } from "@/types/PlayerView"
import {
    WarningAmberRounded as WarningIcon,
    DangerousRounded as ErrorIcon,
    Circle as NormalIcon,
    PendingRounded as WaitingIcon,
} from "@mui/icons-material"
import { ComponentProps, useEffect, useState } from "react"
import { NwkInfo, NwkLatency } from "@/services/NwkInfoService"
//import PlayerTooltip from "@/components/PlayerTooltip"
import { Tooltip } from "@mui/material"
// 240730 Sai: No idea where these 2 came from - the app wont work if remove it
import { BonzaService, LocalRemoteManager } from "@/services/BonzaService"
import { RemoteInfo } from "@/types/RemoteManager"

interface NetworkQualityIndicatorProps extends ComponentProps<"div"> {
    playerName: string
}

function makeIcon(latency: number) {
    if (!latency)
        return (
            <WaitingIcon
                fontSize="small"
                style={{
                    color: "#999",
                    opacity: 0.25,
                    maxWidth: "12px",
                    marginTop: "-2px",
                }}
            />
        )
    if (latency <= NwkLatency.Excellent)
        return (
            <NormalIcon
                fontSize="small"
                style={{ color: "#0f0", maxWidth: "12px", marginTop: "-2px" }}
            />
        )
    if (latency <= NwkLatency.VeryGood)
        return (
            <NormalIcon
                fontSize="small"
                style={{
                    color: "#8bc34a",
                    maxWidth: "12px",
                    marginTop: "-2px",
                }}
            />
        )
    if (latency <= NwkLatency.Good)
        return (
            <NormalIcon
                fontSize="small"
                style={{
                    color: "#ffeb3b",
                    maxWidth: "12px",
                    marginTop: "-2px",
                }}
            />
        )
    if (latency <= NwkLatency.Fair)
        return (
            <NormalIcon
                fontSize="small"
                style={{
                    color: "#ff9800",
                    maxWidth: "12px",
                    marginTop: "-2px",
                }}
            />
        )
    if (latency <= NwkLatency.Poor)
        return (
            <NormalIcon
                fontSize="small"
                style={{
                    color: "#ff6b6b",
                    maxWidth: "12px",
                    marginTop: "-2px",
                }}
            />
        )
    if (latency <= NwkLatency.Terrible)
        return (
            <NormalIcon
                fontSize="small"
                style={{ color: "#f00", maxWidth: "12px", marginTop: "-2px" }}
            />
        )

    return <ErrorIcon fontSize="small" style={{ color: "#c00" }} />
}

export default function NetworkQualityIndicator(
    props: NetworkQualityIndicatorProps
) {
    const { playerName, ...domProps } = props
    const [networkInfo, setNetworkInfo] = useState<RTInfo>({})

    useEffect(() => {
        const networkReceiver = {
            receive: (info: RTInfo) => {
                setNetworkInfo((current) => {
                    return { ...current, ...info }
                })
            },
        }
        NwkInfo.subscribe(playerName, networkReceiver)
        return () => NwkInfo.unsubscribe(playerName)
    }, [])

    const latency = parseInt(networkInfo.latency ?? "")

    // 240730 Sai added more local info to tooltip

    const remote: RemoteInfo | null =
        LocalRemoteManager.findRemoteFromName(playerName)
    const ownName: string | null = BonzaService.user?.name ?? null
    let todisplay: boolean = import.meta.env.VITE_APP_ENV != "production"
    let isLocal = false
    let isRemote = false
    if (remote) {
        isRemote = true
    } else if (ownName && ownName.startsWith(playerName)) {
        isLocal = true
    }
    if (!isLocal && !isRemote) {
        todisplay = false
    }

    return (
        <span {...domProps}>
            <Tooltip
                title={
                    todisplay ? (
                        <>
                            {" "}
                            {isLocal ? (
                                <></>
                            ) : (
                                <>
                                    <em>Latency:</em> {networkInfo.latency}
                                    <br />
                                    <em>Jitter:</em> {networkInfo.jitter}
                                    <br />
                                    <em>
                                        {networkInfo.dropout ==
                                        RTInfoEmptyField ? (
                                            <>&nbsp;</>
                                        ) : (
                                            networkInfo.dropout
                                        )}
                                    </em>
                                    <br />
                                    <em>-----------</em> {}
                                    <br />
                                </>
                            )}
                            <em>External-IP: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo
                                      .externalIP
                                : remote?.engineIP}
                            <br />
                            <em>Internal-IP: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo
                                      .internalIP
                                : remote?.interfaceIP}
                            <br />
                            <em>remote-IP: </em>{" "}
                            {isLocal ? "n/a" : remote?.remoteIP}
                            <br />
                            <em>TCP-Port: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo
                                      .externalPort
                                : remote?.remoteTCPPort}
                            <br />
                            <em>UDP-Port1: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo
                                      .userUDPPort
                                : remote?.ownUDPPort}
                            <br />
                            <em>UDP-Port2: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo
                                      .userUDPPort2
                                : remote?.ownUDPPort2}
                            <br />
                            <em>Lan?: </em>{" "}
                            {isLocal
                                ? "n/a"
                                : remote
                                  ? remote.connectedOnLan
                                      ? "true"
                                      : "false"
                                  : ""}
                            <br />
                            <em>NAT: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo.NAT
                                : remote?.NAT}
                            <br />
                            <em>OS: </em>{" "}
                            {isLocal
                                ? LocalRemoteManager.workingData.selfInfo.OS
                                : remote?.OS}
                            <br />
                        </>
                    ) : (
                        <>
                            <em>Latency: </em> {networkInfo.latency}
                            <br />
                            <em>Jitter: </em> {networkInfo.jitter}
                            <br />
                            <em>
                                {networkInfo.dropout == RTInfoEmptyField ? (
                                    <>&nbsp;</>
                                ) : (
                                    networkInfo.dropout
                                )}
                            </em>
                            <br />
                        </>
                    )
                }
            >
                {makeIcon(latency)}
            </Tooltip>
        </span>
    )
}
