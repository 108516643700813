import { SliderThumb as SliderThumbBase } from "@mui/material"
import { PropsWithChildren } from "react"

export default function SliderThumbVertical({
    children,
    ...props
}: PropsWithChildren<{ horizontal?: boolean }>) {
    return props.horizontal ? (
        ""
    ) : (
        <SliderThumbBase {...props}>
            {children}
            <div style={{ height: "100%", marginTop: "-44px" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44.741"
                    height="74.393"
                    viewBox="0 0 44.741 74.393"
                >
                    <defs>
                        <linearGradient
                            id="linear-gradient"
                            x1="0.5"
                            x2="0.5"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="1" stopColor="#202126" />
                        </linearGradient>
                        <filter
                            id="Rectangle_2058"
                            x="0"
                            y="0"
                            width="44.741"
                            height="74.393"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dy="3" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <linearGradient
                            id="linear-gradient-2"
                            x1="0.5"
                            x2="0.5"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="1" stopColor="#202126" />
                        </linearGradient>
                    </defs>
                    <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Rectangle_2058)"
                    >
                        <rect
                            id="Rectangle_2058-2"
                            width="26.741"
                            height="56.393"
                            rx="13"
                            transform="translate(35.74 62.39) rotate(180)"
                            fill="url(#linear-gradient)"
                        />
                    </g>
                    <rect
                        id="Rectangle_2059"
                        width="23.332"
                        height="53.122"
                        rx="11.666"
                        transform="translate(34.036 60.757) rotate(180)"
                        fill="url(#linear-gradient-2)"
                    />
                    <line
                        id="Line_193"
                        x2="17.447"
                        transform="translate(13.466 34.197)"
                        fill="none"
                        stroke="#455DFF"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_195"
                        x2="17.447"
                        transform="translate(13.466 37.197)"
                        fill="none"
                        stroke="#455DFF"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_194"
                        x2="17.447"
                        transform="translate(13.466 31.197)"
                        fill="none"
                        stroke="#455DFF"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                </svg>
            </div>
        </SliderThumbBase>
    )
}
