import { ReadyState, SocketEvent, WebSocketService } from "@/types/WebSocket"
import { IAgentService } from "@/services/AgentService"
import { Slider } from "@mui/material"
import { ComponentProps, useState } from "react"
import {
    SetLocalVolumeV240124Message,
    SetMasterPanV240124Message,
    SetMasterVolV240124Message,
} from "@/types/AppMessage"
import { SetLocalPanV240124Message } from "@/types/AppMessage"
import SliderThumbVertical from "@/components/parts/SliderThumbVertical"
import SliderThumbHorizontal from "@/components/parts/SliderThumbHorizontal"
import Knob from "./Knob"
import { Agent } from "@/services/BonzaService"

export enum ControlStyleSK {
    Slider,
    Knob,
}

export interface GUIMinMax {
    min: number
    max: number
    default: number
}

export class SliderMinMax implements GUIMinMax {
    min = 0
    max = 100
    default = 100
    static min: number = 0
    static max: number = 100
    static default: number = 100
}

export class KnobMinMax implements GUIMinMax {
    min = -100
    max = 100
    default = 0
    static min: number = -100
    static max: number = 100
    static default: number = 0
}

export const minMax2Float01 = (
    valueMinToMax: number,
    minmax: SliderMinMax | KnobMinMax
): number => {
    const range = minmax.max - minmax.min
    let vf01 = (valueMinToMax - minmax.min) / range
    if (vf01 < 0.0) {
        vf01 = 0
    } else if (vf01 > 1.0) {
        vf01 = 1.0
    }
    return vf01
}

export enum LR_Type {
    Local,
    Remote,
}
/// TODO - merge Local & Remote based on LR_Type !!!

export enum LocalControlType {
    Volume,
    Pan,
}

/**
 * We're using an interface to define how the properties provided to the Control should look. Anywhere a
 * Control is implemented, it needs to be provided with an AgentService. We're not saying the actual class here,
 * just the interface, which we're using to describe the public fields and methods of the service.
 */
interface AudioControlPropsLocal extends ComponentProps<"div"> {
    agent: IAgentService & WebSocketService
    channel: number
    type: LocalControlType
    styleSK: ControlStyleSK
    listener?: (newval: number) => void // ???
}

export default function LocalControl(props: AudioControlPropsLocal) {
    const agent = props.agent

    /*
     * We don't want the control to work if the socket isn't connected, so track 'enabled' as part of the
     * component state.
     */
    const [enabled, setEnabled] = useState(agent.readyState == ReadyState.Open)
    const [level, setLevel] = useState(SliderMinMax.default)
    agent.addEventListener({
        handleSocketEvent(event: SocketEvent) {
            switch (event.type) {
                case "open":
                    setEnabled(true)
                    break

                case "close":
                case "error":
                    setEnabled(false)
            }
        },
    })

    const localPanKnobListener = (newval: number) => {
        if (props.listener) {
            props.listener(newval)
            return
        }
        const val01 = minMax2Float01(newval, new KnobMinMax())
        agent.send(new SetLocalPanV240124Message(props.channel, val01))
    }

    /*
     * The slider value can technically (but actually probably won't) be an array of numbers (eg if a range slider?),
     * so this function is to ensure that the agent is only ever passed a single value to set level.
     * @param level
     */
    const setLevelIn = (levelin: number | Array<number>) => {
        let levelnum: number
        if (levelin instanceof Array) {
            if (levelin.length) {
                levelnum = levelin[0]
            } else {
                levelnum = 0
            }
        } else {
            levelnum = levelin
        }
        setLevel(levelnum)

        const level01s = minMax2Float01(levelnum, new SliderMinMax())
        const level01k = minMax2Float01(levelnum, new KnobMinMax())

        /*
         * Made this a switch only in case we have more types later on, could just as easily be if... else for now.
         */
        switch (props.type) {
            case LocalControlType.Volume:
                if (props.channel == -1) {
                    agent.send(
                        new SetMasterVolV240124Message(
                            props.styleSK == ControlStyleSK.Slider
                                ? level01s
                                : level01k
                        )
                    )
                } else {
                    agent.send(
                        new SetLocalVolumeV240124Message(
                            props.channel,
                            props.styleSK == ControlStyleSK.Slider
                                ? level01s
                                : level01k
                        )
                    )
                }
                Agent.setLocalVolumeForChannel(props.channel, levelnum)
                break

            case LocalControlType.Pan:
                if (props.channel == -1) {
                    agent.send(
                        new SetMasterPanV240124Message(
                            props.styleSK == ControlStyleSK.Slider
                                ? level01s
                                : level01k
                        )
                    )
                } else {
                    agent.send(
                        new SetLocalPanV240124Message(
                            props.channel,
                            props.styleSK == ControlStyleSK.Slider
                                ? level01s
                                : level01k
                        )
                    )
                }
                Agent.setLocalPanForChannel(props.channel, levelnum)
                break

            default: // bad AudioControlType
        }
    }

    if (props.type == LocalControlType.Volume) {
        return (
            <div
                className={`
                    ${props.className}

                    flex flex-col
                `}
            >
                <div
                    className={`w-12 flex-1 bg-bonza-dark px-2 py-8`}
                    style={{
                        borderRadius: "8px",
                    }}
                >
                    <Slider
                        defaultValue={SliderMinMax.default}
                        value={Agent.getLocalVolumeForChannel(props.channel)}
                        className="w-6 py-2"
                        orientation="vertical"
                        // disabled={!enabled}
                        onChange={(_event, value) => setLevelIn(value)}
                        onDoubleClick={(_event) =>
                            setLevelIn(SliderMinMax.default)
                        } // 240729 Sai double click - also  see remote.tsx
                        aria-label="Volume"
                        valueLabelDisplay="auto"
                        slots={{
                            thumb: SliderThumbVertical,
                        }}
                        sx={{
                            "& .Mui-disabled": {
                                filter: "grayscale(0.25) contrast(0.9)",
                            },
                            "& .MuiSlider-track, & .MuiSlider-rail": {
                                width: "4px",
                                border: "none",
                                borderRight: "1px solid rgba(255, 255, 255, 0.6)",
                                backgroundColor: "rgba(255, 255, 255, 0.6)",
                                borderRadius: "5px",
                                opacity: enabled ? 1 : 0.35,
                            },
                            "& .MuiSlider-thumb:hover, & .MuiSlider-thumb:active":
                            {
                                boxShadow: "none",
                            },
                        }}
                    />
                </div>
            </div>
        )
    } else if (
        props.type == LocalControlType.Pan &&
        props.styleSK == ControlStyleSK.Slider
    ) {
        return (
            <div
                className={`
                    ${props.className}

                    flex w-56 flex-row
                `}
            >
                <div
                    className={`flex-1 bg-bonza-dark px-8 py-2`}
                    style={{
                        borderRadius: "8px",
                    }}
                >
                    <Slider
                        defaultValue={SliderMinMax.default}
                        className="px-2"
                        orientation="horizontal"
                        // disabled={!enabled}
                        onChange={(_event, value) => setLevelIn(value)}
                        aria-label="Pan"
                        valueLabelDisplay="auto"
                        slots={{
                            thumb: SliderThumbHorizontal,
                        }}
                        sx={{
                            "& .Mui-disabled": {
                                filter: "grayscale(0.25) contrast(0.9)",
                            },
                            "& .MuiSlider-track, & .MuiSlider-rail": {
                                height: "4px",
                                border: "none",
                                borderBottom: "1px solid rgba(255, 255, 255, 0.6)",
                                backgroundColor: "rgba(255, 255, 255, 0.6)",
                                borderRadius: "5px",
                                opacity: enabled ? 1 : 0.35,
                            },
                            "& .MuiSlider-thumb:hover, & .MuiSlider-thumb:active":
                            {
                                boxShadow: "none",
                            },
                        }}
                    />
                </div>
            </div>
        )
    } else if (
        props.type == LocalControlType.Pan &&
        props.styleSK == ControlStyleSK.Knob
    ) {
        return (
            <div
                className={`
                    ${props.className}

                    flex flex-row
                `}
            >
                <Knob
                    defaultValue={KnobMinMax.default}
                    min={-100}
                    value={Agent.getLocalPanForChannel(props.channel)}
                    // somehow set listener for change???
                    listener={localPanKnobListener}
                />
            </div>
        )
    } else {
        return <div></div>
    }
}
