import {
    Agent,
    Bonza,
    BonzaService,
    LocalDevice,
    LocalRemoteManager,
    SJ_USERID_OFFSET,
    UserIDs,
} from "@/services/BonzaService"
import LoggerService, { LogLevel } from "@/services/LoggerService"
import {
    AudioInitialisationSequenceStates as AudioISStates,
    fNOP,
} from "./Device"
import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
} from "./WebSocket"
import {
    Add_UserMessage,
    DisconnectMessage,
    ROOMMessage,
    HEARTBEATMessage,
    LHStopStreamMessage,
    LoginMessage,
    MirrorLH_SSP,
    MirrorStopStreamMessage,
    RemoteInfoData,
    SJTG_addMessage,
    SetRemotePanMessage,
    StartStreamMessage,
    UDPPortUpdateMessage,
    isLname,
    isLorMname,
    isMname,
    stopStreamMessage,
} from "./AppMessage"
import { AgentService } from "@/services/AgentService"
import { User, UserModel } from "./UserClass"
import { PlayerViewGeneric } from "./PlayerView"
import { PanZoneModel } from "./PanZone"
import {
    MapN01toPN100SI,
    MapPN100Sto01N,
    ZBPMap,
    panFromPanZoneId,
} from "@/proc/Proc"
import { SliderMinMax, KnobMinMax } from "@/components/AudioControlLocal"
import { enqueueSnackbar } from "notistack"

// not sure if this is the best way to do it ie extending SocketEventListener, MessageEventListener?
// socket event happens to work - but sorta need to know if an event or message is from
// Agent ie BonzaApp
// or Bonza ie server ... ALL TBD!!!
//
export interface IRemoteManager {
    addRemoteChangeListener(listener: RemoteChangeListener): void
    removeRemoteChangeListener(
        listener: RemoteChangeListener
    ): RemoteChangeListener | null
}

export type VPSaveRemote = {
    IP: string
    port: string
    vol: number
    pan: number
}

export enum RISSFlags {
    RISS_Uninitialised = 0,
    RISS_SocketOpen = 1,
    RISS_LogInAttempted = 2,
    RISS_LoggedIn = 4, // todo !!! need to detect this by NOT getting a failed response - how? (timeout?)
    RISS_GotPortInfo = 8, // we need these params before can start streaming
    RISS_AudioRunning = 16,
    RISS_Ready = 32,
    RISS_Connected = 64, // not much use - because we can do multiple connects
    RISS_Error = 128,
    RISS_LoginFailed = 256, // maybe need to embed reason? eg FailedNoUSer FailedDupUser
    RISS_RESULT_AUDIO_READY = RISS_SocketOpen +
        RISS_GotPortInfo +
        RISS_AudioRunning,
}

export function RISS_isReady(flag: number): boolean {
    const isReadyORG: boolean =
        (flag & RISSFlags.RISS_SocketOpen) +
            //flag & RISSFlags.RISS_LoggedIn + // for now - until got reliable loggedin detection, omit...
            (flag & RISSFlags.RISS_GotPortInfo) +
            (flag & RISSFlags.RISS_AudioRunning) ==
        RISSFlags.RISS_RESULT_AUDIO_READY // 240428 can amalg

    const isReadyNEW: boolean =
        (flag & RISSFlags.RISS_RESULT_AUDIO_READY) ==
        RISSFlags.RISS_RESULT_AUDIO_READY
    if (isReadyORG !== isReadyNEW) {
        LocalRemoteManager.logger.error(
            `RM:50 isReadyORG ${isReadyORG} != isReadyNEW ${isReadyNEW}`
        )
    }
    return isReadyNEW
}

export enum RemoteConnectState {
    disconnected = 0,
    trying, // 1
    connected, // 2
    verified, // 3
}

export enum RemoteChangeEventType {
    Readyness,
    Add,
    Remove,
    RemoveAll,
}

enum DisconnectResult {
    OK,
    NotConnected,
    NotPresent,
    BadParams,
}

// https://stackoverflow.com/questions/38965455/try-to-validate-ip-address-with-javascript
// or use http://adilapapaya.com/docs/ipaddr.js ?
export function isIP(ip: string) {
    // if (typeof (ip) !== 'string') {
    //     return false;
    // }
    if (ip.length <= 0) {
        return false
    }
    if (!ip.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/)) {
        return false
    }
    return (
        ip
            .split(".")
            .filter(
                (octect) => parseInt(octect) >= 0 && parseInt(octect) <= 255
            ).length === 4
    )
}

class SelfInfo {
    public internalIP: string
    public internalPort: string
    public externalIP: string
    public userUDPPort: string
    public userUDPPort2: string
    public NAT: string
    public OS: string
    public externalPort: string
    public assignedUserIDSJ: string
    public constructor() {
        this.internalIP = ""
        this.internalPort = ""
        this.externalIP = ""
        this.userUDPPort = ""
        this.userUDPPort2 = ""
        this.NAT = ""
        this.OS = ""
        this.externalPort = ""
        this.assignedUserIDSJ = ""
    }

    /*    setSelfInfoFromSrc( src:SelfInfo ) {
            this.userName = src.userName;
            this.userUDPPort = src.userUDPPort;
            this.userUDPPort2 = src.userUDPPort2;
            this.engineIP = src.engineIP
            this.interfaceIP = src.interfaceIP;
            this.userID = src.userID;
        }
    */

    /*
        setSelfInfoS(userUDPPort: string, userUDPPort2: string,
        interfaceIP: string, engineIP: string, NAT: string, externalPort: string = '') {
        this.userUDPPort = userUDPPort;
        this.userUDPPort2 = userUDPPort2;
        this.externalIP = engineIP
        this.internalIP = interfaceIP;
        this.NAT = NAT;
        this.externalPort = externalPort;
    }
    */

    isValid_ORG(): boolean {
        const includeNameAndIDInCheck = true // but if name is OK then userID and userIDSJ should also be OK
        if (includeNameAndIDInCheck) {
            if (
                !BonzaService.user ||
                !BonzaService.user.name ||
                BonzaService.user.name.length == 0
            ) {
                return false
            }
            if (!BonzaService.user.id || isNaN(BonzaService.user.id)) {
                return false
            }
        }
        const isValid: boolean =
            this.internalPort.length > 0 &&
            this.userUDPPort.length > 0 &&
            this.externalIP.length > 0 &&
            isIP(this.externalIP) &&
            this.internalIP.length > 0 &&
            isIP(this.internalIP)
        return isValid
    }

    isValid(): boolean {
        const includeNameAndIDInCheck = true // but if name is OK then userID and userIDSJ should also be OK
        if (includeNameAndIDInCheck) {
            if (
                !BonzaService.user ||
                !BonzaService.user.name ||
                BonzaService.user.name.length == 0
            ) {
                return false
            }
            if (!BonzaService.user.id || isNaN(BonzaService.user.id)) {
                return false
            }
        }
        const isValid1: boolean =
            this.internalPort.length > 0 &&
            this.userUDPPort.length > 0 &&
            this.externalIP.length > 0 &&
            isIP(this.externalIP) &&
            this.internalIP.length > 0 &&
            isIP(this.internalIP)
        if (isValid1) {
            return isValid1
        }
        // UoY special case: one of internal/ext only
        let isValid2: boolean = false
        if (
            (isIP(this.internalIP) || isIP(this.externalIP)) &&
            (this.internalPort.length || this.userUDPPort.length)
        ) {
            isValid2 = true
        }
        if (isValid2) {
            fNOP()
        } else {
            fNOP()
        }
        return isValid2
    }
}

export class RemoteInfo {
    public index: number = -1 // index in the array, -1 = invalid.
    public remoteName: string = ""
    public remoteConnectState: RemoteConnectState =
        RemoteConnectState.disconnected
    public connectedOnLan = false
    // for startStream - not sure where get these from ???
    public remoteUserID: string = "0"
    public systemID: string = "0"
    public interfaceIP: string = "" // internal eg 127.0.0.X
    public engineIP: string = "" // external
    public remoteIP: string = "" // added 240803 KB - in case of BonzaMirror this will differ from engineIP
    // from UDP data msg - not sure if useful???
    public ownUDPPort: string = ""
    public ownUDPPort2: string = "" // this should contain "port (NAT)"
    public OS: string = ""
    public NAT: string = "" // probably not used!
    public remoteTCPPort: string = ""
    public remoteTCPAddr: string = ""
    public zoneid: number = -1
    public panpn100s: string = ""
    public isTest: boolean = false

    public constructor(index: number) {
        this.index = index
    }

    public isValid(): boolean {
        const isvalid: boolean =
            this.remoteName.length > 0 &&
            this.interfaceIP.length > 0 &&
            isIP(this.interfaceIP) &&
            this.engineIP.length > 0 &&
            isIP(this.engineIP) &&
            this.ownUDPPort.length > 0 &&
            this.index >= 0
        // poss more todo ???
        if (isvalid) {
            fNOP()
        } else {
            fNOP()
        }
        return isvalid
    }

    public makeNatString(): string {
        const possstr1: string = this.ownUDPPort2
        // ???? or form it from other port and nat?
        const possstr2 = `${this.ownUDPPort} (${this.NAT})`

        return possstr2
    }
}

class workingDataStore {
    public TPInfo: any
    public selfInfo: SelfInfo = new SelfInfo()
    public remotes: Array<RemoteInfo> = []
    public currentRemoteInProgressName = ""
    constructor() {
        //this.remotes.push(new RemoteInfo(0)); // add a localhost at index 0 ...
    }
}

export interface RemoteChangeListener {
    handleRemoteChange(
        manager: IRemoteManager,
        eventType: RemoteChangeEventType,
        info: RemoteInfo
    ): void
}

export class RemoteManager implements IRemoteManager {
    private _listeners: Array<RemoteChangeListener> = []
    public logger: LoggerService = new LoggerService("RemoteManager") // 240326 make public so can log from reducer
    private _initialisationState: RISSFlags = RISSFlags.RISS_Uninitialised
    //private user: UserModel | null = null;
    public workingData: workingDataStore
    private idt1: any = null

    private tpAndPipCount: number = 0
    private VUCount: number = 0
    public useTempZBP: boolean = true

    constructor() {
        this.workingData = new workingDataStore()
        Agent.addEventListener(this.agentEventListener)
        Agent.addMessageListener(this.agentMessageListener)
        Bonza.addEventListener(this.bonzaEventListener)
        Bonza.addMessageListener(this.bonzaMessageListener)
    }

    // REMOTE VP saves (totally sep from either the remotes array or players for now)
    private _RemoteIPPVPSaves = new Array<VPSaveRemote>()
    // caveat: assumes vol uses a Slider and pan uses a Knob
    // NOTE: KB 2406528 If/when the control is displayed initially it MAY still only have IP as remote.remoteName & port ''
    // that works since I moved setting the vals to outside the isValidIP etc checks...
    // (ie in the below checks - it still finds an entry if name matches & there is no port.)
    // the problem is when it gets verified & the IP and port change to the real ones...
    // I attempt to transfer in verifyRemote but I think there's still an edge case that doesnt work...
    public getRemoteVolumeForIPP(IP: string, port: string) {
        const index = this._RemoteIPPVPSaves.findIndex(
            (p) => p.IP === IP && p.port === port
        )
        if (index >= 0) {
            return this._RemoteIPPVPSaves[index].vol
        } else {
            return SliderMinMax.default
        }
    }

    public setRemoteVolumeForIPP(IP: string, port: string, value: number) {
        const index = this._RemoteIPPVPSaves.findIndex(
            (p) => p.IP === IP && p.port === port
        )
        if (index >= 0) {
            this._RemoteIPPVPSaves[index].vol = value
        } else {
            const VP: VPSaveRemote = {
                IP: IP,
                port: port,
                vol: value,
                pan: KnobMinMax.default,
            }
            this._RemoteIPPVPSaves.push(VP)
        }
    }

    public getRemotePanForIPP(IP: string, port: string) {
        const index = this._RemoteIPPVPSaves.findIndex(
            (p) => p.IP === IP && p.port === port
        )
        if (index >= 0) {
            return this._RemoteIPPVPSaves[index].pan
        } else {
            return KnobMinMax.default
        }
    }

    public setRemotePanForIPP(IP: string, port: string, value: number) {
        const index = this._RemoteIPPVPSaves.findIndex(
            (p) => p.IP === IP && p.port === port
        )
        if (index >= 0) {
            this._RemoteIPPVPSaves[index].pan = value
        } else {
            const VP: VPSaveRemote = {
                IP: IP,
                port: port,
                vol: SliderMinMax.default,
                pan: value,
            }
            this._RemoteIPPVPSaves.push(VP)
        }
    }

    timedEventFunction() {
        this.sendHeartbeat()
        // where do I check audio OK & thus progress to RISS_Ready?
    }

    /**
     * @deprecated Use connect instead (user is already logged in)
     * @param user
     */
    login(user: User) {
        if (this.initState & RISSFlags.RISS_SocketOpen) {
            Bonza.send(new LoginMessage(user.name))
            this.setLoginAttempted(user)
        } else {
            fNOP() // ???
        }
    }

    connect(identifier: string) {}

    setLoginAttempted(user: UserModel) {
        this.initState |= RISSFlags.RISS_LogInAttempted
    }

    removeTryingNoSuchUser(remoteName: string) {
        const remote = this.findRemoteFromName(remoteName)
        let msgHeader: string = `Server says no such user ${remoteName}`
        if (remote) {
            if (remote.isTest) {
                msgHeader += ` but it was connected via TEST so NOT removing it...`
            } else {
                msgHeader += `... removing`
            }
            this.logger.warn(`${msgHeader}`)
            // alert(`${msgHeader}`);
            enqueueSnackbar(`${msgHeader}`, {
                variant: "info",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
            if (!remote.isTest) {
                this.delRemoteFromArrayByName(remote)
                this.notify(RemoteChangeEventType.Remove, remote)
            }
        } else {
            fNOP() // no remote?
            this.logger.warn(
                `${msgHeader} and there was no remote in array... ignoring`
            )
        }
    }

    setNoSuchUser(remoteName: string /* or empty */) {
        let existsAndMatches: boolean = false
        const lastTry: string = this.workingData.currentRemoteInProgressName
        if (remoteName.length) {
            if (remoteName == lastTry) {
                existsAndMatches = true
            }
        } else {
            remoteName = lastTry
        }
        this.removeTryingNoSuchUser(remoteName)
    }

    setLoginFailed() {
        this.initState |= RISSFlags.RISS_LoginFailed
        this.logger.error(`Login failed for ${BonzaService.user?.name}`)
        // 'SJTG_name_exists'
        alert(
            `Another user is currently logged in with this name (${BonzaService.user?.name}) - please pick a different one`
        )
        this.logger.todo(
            `Login failed for ${BonzaService.user?.name} - TO MITIGATE`
        )
        Bonza.leave()
        // Chris - how to log the user out & put back to initial screen?
        // ????????????????????????????????????????????
    }

    setLoginSucceeded() {
        if (this.initState & RISSFlags.RISS_LogInAttempted) {
            this.initState |= RISSFlags.RISS_LoggedIn
            this.logger.info("RISS_LoggedIn")
        } else {
            this.logger.warn("RM:263 Potential sequencing error")
        }
    }

    findRemoteFromPortSkt(
        IP: string,
        remoteTCPPort: string
    ): RemoteInfo | null {
        const nRemotes = this.workingData.remotes.length
        for (let i = 0; i < nRemotes; i++) {
            var remote: RemoteInfo
            if (
                this.workingData.remotes[i].engineIP == IP &&
                this.workingData.remotes[i].remoteTCPPort == remoteTCPPort
            ) {
                remote = this.workingData.remotes[i]
                if (remote.engineIP != remote.remoteTCPAddr) {
                    fNOP() // possibly BonzaMirror?
                } else {
                    fNOP() // normal case (they are the same)
                }
                // if necc fix index
                if (remote.index == null || remote.index == -1) {
                    this.logger.error(`remote index should be valid`)
                    this.workingData.remotes[i].index = i
                    remote.index = i
                }
                return remote
            }
            if (
                this.workingData.remotes[i].remoteTCPAddr == IP &&
                this.workingData.remotes[i].remoteTCPPort == remoteTCPPort
            ) {
                remote = this.workingData.remotes[i]
                if (remote.engineIP != remote.remoteTCPAddr) {
                    fNOP() // !!!
                }
                // if necc fix index
                if (remote.index == null || remote.index == -1) {
                    this.logger.error(`remote index should be valid`)
                    this.workingData.remotes[i].index = i
                    remote.index = i
                }
                return remote
            }
        }
        return null
    }

    findRemoteFromIDSJ(
        IDSJ: string,
        engineIP: string
    ): [RemoteInfo | null, boolean | null] {
        const nRemotes = this.workingData.remotes.length
        for (let i = 0; i < nRemotes; i++) {
            var remote: RemoteInfo
            if (this.workingData.remotes[i].remoteUserID == IDSJ) {
                remote = this.workingData.remotes[i]
                let IPmatched: boolean = false
                if (this.workingData.remotes[i].engineIP == engineIP) {
                    IPmatched = true
                }
                return [remote, IPmatched]
            }
        }
        return [null, null]
    }

    findRemoteIndexFromPortSkt_DNU(IP: string, port: string): number | null {
        // !!! todo make lambda, ensure index set at point of adding...
        const nRemotes = this.workingData.remotes.length
        let index: number | null = null
        for (let i = 0; i < nRemotes; i++) {
            if (
                this.workingData.remotes[i].engineIP == IP &&
                this.workingData.remotes[i].ownUDPPort == port
            ) {
                index = i
                return i
            }
        }
        return null
    }

    findRemoteFromName(name: string): RemoteInfo | null {
        // !!! todo make lambda, ensure index set at point of adding...
        const nRemotes = this.workingData.remotes.length
        for (let i = 0; i < nRemotes; i++) {
            var remote: RemoteInfo
            if (this.workingData.remotes[i].remoteName == name) {
                remote = this.workingData.remotes[i]
                return remote
            }
        }
        return null
    }

    handleUpdateUDPPortData(data: any) {
        // ie incoming 'Update UDP port' message
        const index = this.workingData.remotes.length
        const remote: RemoteInfo = new RemoteInfo(index)
        /* from Alex' server
            ownUDPPort
            ownUDPPort2
            NAT
            interfaceIP
            OS
            engineIP
            remoteID
        */

        /* eg message < ones actually not garbage are tabbed left 1 tab >
        Update UDP port, {
        "NAT":"381",
        "OS":"MAC OSX",
        "action":"Update UDP port",
            "appStatus":"NO",
            "audioStatus":"YES",
        "engineIP":"82.41.0.201",
            "groupPosition":"1",
            "index":0,
        "interfaceIP":"192.168.0.47",
            "level":60,
        "message":"UDP port update",
            "ownIP":"127.0.0.1",
            "ownPort":"50050",
            "password":"NONE",
        "remoteID":"3",
            "remoteIP":"82.41.0.201",
            "remoteName":"Ken Brown",
            "remotePort":"54159",
            "remoteSystemID":"6",
        "remoteUDPPort":"9807",
        "remoteUDPPort2":"9807 (381)",
            "roomName":"SJL",
        "type":"system",
            "userName":"",
            "yesno":"YES"}
        */
        remote.ownUDPPort = data.remoteUDPPort
        remote.ownUDPPort2 = data.remoteUDPPort2
        remote.NAT = data.NAT
        remote.interfaceIP = data.interfaceIP
        remote.OS = data.OS
        remote.engineIP = data.engineIP
        remote.remoteUserID = data.remoteID

        //this.logger.todo('RM:392 - what to do with remote user UDP data?'); // 240703 - I think its handled properly already
        //
        // should we add this 'emptyish' user, or just update if found? NO - we get these even from unconnected users!!!
        const TOADD: boolean = false // for now dont add!
        // const debIsLorM = isLorM(remote.engineIP);
        // if (debIsLorM) {
        //     this.logger.todo(`RM:398 handleUpdateUDPPortData ${remote.engineIP} is L or M`);
        // }
        let existingremote: RemoteInfo | null
        let IPmatched: boolean | null
        ;[existingremote, IPmatched] = this.findRemoteFromIDSJ(
            remote.remoteUserID,
            remote.engineIP
        )
        if (!existingremote) {
            if (TOADD) {
                this.addRemoteToArray(remote)
                // then we must return BUT - how could that 'empty' remote ever be valid? there's no mechanism for updating
                // its name etc... so basically TOADD should always be false...
                return
            } else {
                return // ignore
            }
        } else {
            if (!IPmatched) {
                return // ignore I think...
            }
            const index: number = existingremote.index
            let hasChanged: boolean = false
            const debCurrState: RemoteConnectState =
                this.workingData.remotes[index].remoteConnectState
            // need to check userListNS: if any of these have changed & remote is connected - what do we have to do?
            if (
                this.workingData.remotes[index].ownUDPPort != data.remoteUDPPort
            ) {
                hasChanged = true
                this.workingData.remotes[index].ownUDPPort = data.remoteUDPPort
            }

            if (
                this.workingData.remotes[index].ownUDPPort2 !=
                data.remoteUDPPort2
            ) {
                hasChanged = true
                this.workingData.remotes[index].ownUDPPort2 =
                    data.remoteUDPPort2
            }
            if (this.workingData.remotes[index].NAT != data.NAT) {
                hasChanged = true
                this.workingData.remotes[index].NAT = data.NAT
            }
            if (
                this.workingData.remotes[index].interfaceIP != data.interfaceIP
            ) {
                hasChanged = true
                this.workingData.remotes[index].interfaceIP = data.interfaceIP
            }
            if (this.workingData.remotes[index].engineIP != data.engineIP) {
                hasChanged = true
                this.workingData.remotes[index].engineIP = data.engineIP
            }
            this.workingData.remotes[index].OS = data.OS
            if (hasChanged) {
                if (debCurrState == RemoteConnectState.disconnected) {
                    // would be ok to update - however we shouldnt HAVE disconnected remotes in the array -
                    // currently they get removed!
                    fNOP()
                } else {
                    // trying or connected or verified - what to DO?
                    this.logger.todo(
                        `RM:443 handleUpdateUDPPortData : something changed`
                    )
                    // may need to send Agent a changeStreamPort(ID,port) - but which port is 'port' ? poss ownUDPPort ???
                }
            }
        }
    }

    updateRemoteFromAddUserData(
        remote: RemoteInfo,
        data: Add_UserMessage
    ): boolean {
        if (data.NAT == "N/A" || data.remoteUDPPort == "N/A") {
            // prob just a "Userlist update" not an actual connect request
            return false
        }
        if (remote.remoteName.length) {
            if (remote.remoteName != data.remoteName) {
                this.logger.warn(
                    `RM:454 attempting connect to ${remote.remoteName} but AddUser.data.name was ${data.remoteName}`
                )
            }
        } else {
            fNOP() // remote is prob a brand new remote created due to add user msg - will be totally empty!
        }
        remote.remoteName = data.remoteName
        // remote.engineIP = data.remoteIP;
        // usually engineIP is the same as remoteIP except in special cases eg BonzaMirror
        remote.engineIP = data.engineIP // we must use engineIP not remoteIP KB 240522
        remote.remoteIP = data.remoteIP // added 240803 KB
        remote.interfaceIP = data.interfaceIP
        remote.ownUDPPort = data.remoteUDPPort
        remote.ownUDPPort2 = data.remoteUDPPort2
        remote.NAT = data.NAT
        if (Number(data.remoteID) < SJ_USERID_OFFSET) {
            this.logger.warn(
                `updateRemoteFromAddUserData: ${data.remoteID} < ${SJ_USERID_OFFSET}`
            )
        }
        remote.remoteUserID = data.remoteID
        remote.remoteTCPPort = data.remotePort
        remote.remoteTCPAddr = data.remoteIP
        return true
    }

    /*
        "Add User"
        userPort     = msg.remotePort;
        userIP       = msg.remoteIP;
        userID       = msg.remoteID;
        systemID     = 0;
        interfaceIP  = msg.interfaceIP;
        engineIP     = msg.engineIP;

        and an example message: (ones useful? (tbc) *)
            action: 'Add user'
            appStatus: 'YES'
            audioStatus: 'YES'
        *engineIP: '82.41.0.201'
            groupPosition: '25'
            index: 0
        *interfaceIP: '192.168.0.173'
            level: 65
            message: 'Userlist update'
        ?NAT: '181'
            OS: 'WIN'
            ownIP: '82.41.0.201'
            ownPort: '63208'
            password: 'none'
        *remoteID: '2'
        *remoteIP: '82.41.0.201'
            remoteName: 'Ken'
        *remotePort: '49975'
        ?remoteSystemID: '26447'
            remoteUDPPort: '50050'
            remoteUDPPort2: '50050 (181)'
            roomName: 'LabChor'
            type: 'system
    */

    /* ULNS:1129... what to do if remote is on same local nwk?
        if ( userIP == array[index].IP ){
            streamIP = array[index].interfaceIP;
            streamPort = 50050;
        } else{
            streamIP = array[index].IP;
            streamPort = array[index].portUDP;
        }
    */
    handleAddUserOLD_NU(data: Add_UserMessage) {
        /*  actually its pretty simple... se ULNS:427
            a) if already in the list just do nothing
            b) if its US (same name) update self info from the data. // not done yet !!!
            c) else just create a remote & update it from data, add to list & connect...
        */
        const index: number | null = this.findRemoteIndexFromPortSkt_DNU(
            data.engineIP,
            data.remotePort
        )
        if (index) {
            // in already
            return
        }
        if (data.remoteName === BonzaService.user?.name) {
            // presume this is a "Userlist update" message...
            return
        }
        let remote: RemoteInfo | null = this.findRemoteFromName(data.remoteName)
        let exists: boolean
        if (remote != null) {
            // in already but prob with just a name...
            if (remote.remoteConnectState != RemoteConnectState.connected) {
                exists = true
            } else {
                return // already in progress???
            }
        } else {
            exists = false
            const thisRemoteIndex = this.workingData.remotes.length
            remote = new RemoteInfo(thisRemoteIndex)
        }
        const worked: boolean = this.updateRemoteFromAddUserData(remote, data)
        if (worked) {
            if (remote.remoteConnectState != RemoteConnectState.trying) {
                this.logger.todo(
                    `RM 524 ${remote.remoteName} should already be trying`
                )
            }
            remote.remoteConnectState = RemoteConnectState.connected
            this.startNetStream4R(remote)
            if (!exists) {
                this.workingData.remotes.push(remote)
                this.notify(RemoteChangeEventType.Add, remote)
            } else {
                this.notify(RemoteChangeEventType.Readyness, remote)
            }
        } else {
            this.logger.warn(
                `Add User fail due to bad data : ${JSON.stringify(data)}`
            )
        }
    }

    handleAddUser(data: Add_UserMessage): boolean {
        /*  actually its pretty simple... se ULNS:427
            a) if already in the list just do nothing
            b) if its US (same name) update self info from the data. // not done yet !!!
            c) else just create a remote & update it from data, add to list & connect...

            // hmm actually BECAUSE we add to list when JUST name known with state trying...
            // we do have to check for that & update it to connected...
        */
        if (data.remoteName.length == 0) {
            return false
        }
        if (!BonzaService.user || BonzaService.user.name.length == 0) {
            return false
        }
        if (data.remoteName == BonzaService.user.name) {
            // not all data present when its our own name
            // poss useful info: NAT, remoteIP and remotePort ???
            // hmm doesnt exactly match what we already know from tellPort?

            /* actually it DOES tell us our sj-assigned userID & other things...
                See Alex email 240424 re what this contains & where:
                "in order to retrieve the actual user list ID the server sends the following message right after the login:""
                sendObject["type"] = "system";
                sendObject["message"] = "Userlist update";
                sendObject["action"] = "Add user";
                sendObject["remoteName"] = ownName;
                sendObject["remoteIP"] = senderIP;
                sendObject["remotePort"] = portAddressString;
                sendObject["remoteUDPPort"] = "N/A";
                sendObject["remoteUDPPort2"] = "N/A";
                sendObject["remoteID"] = ownID;
                sendObject["remoteSystemID"] = ownSystemID;
                sendObject["interfaceIP"] = "N/A";
                sendObject["OS"] = "N/A";
                sendObject["engineIP"] = "N/A";
            */
            if (Number(data.remoteID) < SJ_USERID_OFFSET) {
                this.logger.warn(`Add_User (self) SJ id = ${data.remoteID}`)
                this.workingData.selfInfo.assignedUserIDSJ = data.remoteID
            }

            LocalRemoteManager.workingData.selfInfo.externalPort =
                data.remotePort
            // we might need this for eventual KILL msg ???
            // to chk w Alex

            if (
                (LocalRemoteManager.initState & RISSFlags.RISS_LoggedIn) == 0 &&
                (LocalRemoteManager.initState & RISSFlags.RISS_LoginFailed) == 0
            ) {
                LocalRemoteManager.setLoginSucceeded()
            }

            return false
        }
        let remote: RemoteInfo | null = this.findRemoteFromName(data.remoteName)
        let hasIPandPort: boolean = false
        let exists: boolean = false
        //this.findRemoteFromPortSkt(data.engineIP,data.remotePort);
        if (remote != null) {
            // in already and has valid port & socket
            exists = true
            if (
                remote.engineIP &&
                isIP(remote.engineIP) &&
                remote.remoteTCPPort.length
            ) {
                hasIPandPort = true
                if (
                    remote.remoteConnectState == RemoteConnectState.disconnected
                ) {
                    this.logger.todo(
                        `RM 501 ${remote.remoteName} should already be at least connected`
                    )
                } else if (
                    remote.remoteConnectState == RemoteConnectState.trying
                ) {
                    this.logger.todo(
                        `RM 501 ${remote.remoteName} should already be at least connected`
                    )
                } else if (
                    remote.remoteConnectState == RemoteConnectState.connected
                ) {
                    return false // already pres & connected - no need to re-add
                } else if (
                    remote.remoteConnectState == RemoteConnectState.verified
                ) {
                    return false // already pres & verified - no need to re-add
                }
            } else {
                // hasIPandPort == false - so it should only be 'trying' ...
                if (remote.remoteConnectState != RemoteConnectState.trying) {
                    this.logger.todo(
                        `RM 524 ${remote.remoteName} should already be trying`
                    )
                } else {
                    fNOP() // OK
                }
            }
        } else {
            const thisRemoteIndex = this.workingData.remotes.length
            remote = new RemoteInfo(thisRemoteIndex)
            remote.remoteConnectState = RemoteConnectState.trying
        }
        const worked: boolean = this.updateRemoteFromAddUserData(remote, data)
        if (worked) {
            remote.remoteConnectState = RemoteConnectState.connected
            this.startNetStream4R(remote)
            if (!exists) {
                //this.workingData.remotes.push(remote);
                this.addRemoteToArray(remote)
                this.notify(RemoteChangeEventType.Add, remote)
                //this.notify(RemoteChangeEventType.Readyness, remote); // ??? added 240226 jic fix VU prob...
            } else {
                this.notify(RemoteChangeEventType.Readyness, remote)
                //this.notify(RemoteChangeEventType.Remove, remote);
                //this.notify(RemoteChangeEventType.Add, remote);
            }
        } else {
            this.logger.warn(
                `Add User fail due to bad data : ${JSON.stringify(data)}`
            )
        }
        return worked
    }

    handleDisconnect(
        IP: string,
        port: string,
        source_internal: boolean = false,
        isLogout: boolean = false
    ): DisconnectResult {
        // if (IP === undefined || port === undefined) {
        //     return DisconnectResult.BadParams;
        // }
        const remote: RemoteInfo | null = this.findRemoteFromPortSkt(IP, port)
        let result = DisconnectResult.OK
        if (remote == null) {
            if (!isLogout) {
                this.logger.warn(`on handleDisconnect no DB entry`)
            } else {
                this.logger.info(`on handleDisconnect for Logout no DB entry`)
            }
            // also no need to delete the entry:)
            result = DisconnectResult.NotPresent
            return result
        }
        if (remote.remoteConnectState == RemoteConnectState.disconnected) {
            result = DisconnectResult.NotConnected
            // but attempt stopStream regardless...
            this.logger.warn(`RM:548 remote in DB was not connected`)
        }
        if (IP.length && port.length) {
            var msg: stopStreamMessage = new stopStreamMessage(IP, port)
            Agent.send(msg)
        }
        // belt an braces for BonzaMirror or any remote with differing IPs
        if (remote.engineIP !== remote.remoteTCPAddr) {
            if (remote.remoteTCPAddr !== IP) {
                if (remote.remoteTCPAddr.length && port.length) {
                    msg = new stopStreamMessage(remote.remoteTCPAddr, port)
                    Agent.send(msg)
                }
            }
            if (remote.engineIP !== IP) {
                if (remote.engineIP.length && port.length) {
                    msg = new stopStreamMessage(remote.engineIP, port)
                    Agent.send(msg)
                }
            }
        }
        // if (remote.connectedOnLan) {
        //     // JIC !!! < no need - it *does* use the engine (ext) IP not internal ip...
        //     const ssm = new stopStreamMessage(remote.interfaceIP, remote.remoteTCPPort);
        //     Agent.send(ssm);
        // }
        remote.remoteConnectState = RemoteConnectState.disconnected
        const toRemoveAfterDisconnect = true // yes we should always remove it...
        if (toRemoveAfterDisconnect) {
            this.notify(RemoteChangeEventType.Remove, remote) // NOTE: this removes by name, not IP or port...
            const oldLen = this.workingData.remotes.length
            this.delRemoteFromArrayByName(remote)
            const newLen = this.workingData.remotes.length
            if (oldLen != newLen + 1) {
                // delRemoteFromArray already logs this event...
            } else {
                this.logger.info(
                    `on handleDisconnect successfully removed remote ${remote.remoteName}`
                )
            }

            // 240701 - also zap any saved remote pan & vol states from save array
            // we should do this because BA will initialise a newly connected remote to the default vol & pan
            // even if its previously been connected in the past...
            // until we have 'saved presets' we need to make sure this array DOESNT put the gui into
            // some remembered state (which would be inappropriate because BA will be at default, and we do
            // not yet have any code to re-send any 'remembered' state for that remote to BA)
            const remoteName = remote.remoteName
            const remoteIP = remote.remoteTCPAddr
            const remotePort = remote.remoteTCPPort
            // remove if name matches or (IP and port) matches
            const deblenPre = this._RemoteIPPVPSaves.length
            this._RemoteIPPVPSaves = this._RemoteIPPVPSaves.filter(
                (p) =>
                    !(
                        p.IP === remoteName ||
                        (p.IP === remoteIP && p.port === remotePort)
                    )
            )
            const deblenPost = this._RemoteIPPVPSaves.length
            if (deblenPre != deblenPost) {
                fNOP()
            } else {
                fNOP()
            }
        }
        return result
    }

    handleKill() {}

    public get initState() {
        return this._initialisationState
    }

    public set initState(newState: RISSFlags) {
        this._initialisationState = newState
    }

    public addRemoteChangeListener(listener: RemoteChangeListener) {
        if (this._listeners.indexOf(listener) == -1) {
            this._listeners.push(listener)
        }
    }

    public removeRemoteChangeListener(
        listener: RemoteChangeListener
    ): RemoteChangeListener | null {
        const x = this._listeners.indexOf(listener)
        if (x > -1) {
            return this._listeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    // hmm - actually we shouldnt listen for all low level messages - we should register interest in just the TYPE's
    // we want? is that poss???

    agentEventListener: SocketEventListener = {
        handleSocketEvent(event: SocketEvent) {
            if (event.type == "open") {
                fNOP()
            } else if (event.type == "message") {
                fNOP()
            } else if (event.type == "close") {
                fNOP()
            } else if (event.type == "error") {
                fNOP()
            }
        },
    }

    agentMessageListener: MessageEventListener = {
        handleMessageEvent(
            event: MessageEvent,
            eventData: any | null | undefined
        ) {
            if (!eventData || !eventData.type) return

            switch (eventData.type) {
                case "tellPort":
                    const valOLD: boolean =
                        (LocalRemoteManager.initState &
                            ~RISSFlags.RISS_GotPortInfo) ==
                        0
                    const valNEW: boolean =
                        (LocalRemoteManager.initState &
                            RISSFlags.RISS_GotPortInfo) ==
                        0 // 240428
                    if (valOLD !== valNEW) {
                        //LocalRemoteManager.logger.error(`RM 761 valOLD ${valOLD} !== valNEW ${valNEW}`);
                        fNOP()
                    }
                    if (
                        LocalRemoteManager.tpAndPipCount++ % 10 == 0 ||
                        valNEW
                    ) {
                        // only log if not flagged as got, or 1 in 10
                        LocalRemoteManager.logger.info(
                            `RM:791 tellPort: ${JSON.stringify(eventData)}`
                        )
                    }

                    /*
                    "type":"tellPort"
                    "NAT":"181","OS":"MAC OSX","interfaceIP":"192.168.0.21","localBindPort":"50050",
                    "localIP":"82.41.0.201","localIP2":"82.41.0.201","localPort":"50050","localPort2":"50050 (181)",

                    userUDPPort = msg.localPort;
                    portInformationToPortal(msg.localIP, msg.localPort, msg.localPort2, msg.NAT, msg.interfaceIP, msg.OS);
                    localBindPortToPortal(msg.localBindPort);

                    */
                    // once we've received a tellPort we can init getting ready for remote connedtions...

                    /// hmm would be better notifying & having a RISSManager so all the logic is in one place?
                    /// because AudioReady & GotPortInfo are two sep boolean states!!!

                    LocalRemoteManager.initState |= RISSFlags.RISS_GotPortInfo
                    LocalRemoteManager.checkRISSStatus()

                    LocalRemoteManager.workingData.TPInfo = eventData // save it here as well ...
                    if (!BonzaService.user) {
                        //alert("Received tellPort but no user yet?");
                        // 240321 - not an error...
                    } else {
                        LocalRemoteManager.workingData.selfInfo.userUDPPort =
                            eventData.localPort
                        ;(LocalRemoteManager.workingData.selfInfo.userUDPPort2 =
                            eventData.localPort2), // eg '50050 (181)'
                            (LocalRemoteManager.workingData.selfInfo.externalIP =
                                eventData.localIP)
                        LocalRemoteManager.workingData.selfInfo.internalIP =
                            eventData.interfaceIP
                        LocalRemoteManager.workingData.selfInfo.NAT =
                            eventData.NAT
                        LocalRemoteManager.workingData.selfInfo.OS =
                            eventData.OS
                        LocalRemoteManager.workingData.selfInfo.internalPort =
                            AgentService.getAgentPort().toString()
                    }

                    // now send our info ??? or do we if Bonza/SJL - poss not???
                    const debsentok: boolean =
                        LocalRemoteManager.portInformationToPortal(
                            eventData.localIP,
                            eventData.localPort,
                            eventData.localPort2,
                            eventData.NAT,
                            eventData.interfaceIP,
                            eventData.OS
                        )

                    break

                case "setRemoteSoundLevel":
                    // handled by VUMeter directly
                    // 240223 INSERT: why only wkg when remote connects to US?
                    const oneInNToLog = 250
                    if (LocalRemoteManager.VUCount >= oneInNToLog) {
                        LocalRemoteManager.VUCount = 0
                        LocalRemoteManager.logger.info(
                            `RM:779 VUData : ${JSON.stringify(eventData)}`
                        )
                    }
                    if (LocalRemoteManager.VUCount % 50 == 0) {
                        //we can use this to verify a remote conn...
                        const IP: string = eventData.data3
                        const portTCP: string = eventData.data4
                        LocalRemoteManager.verifyRemote(IP, portTCP)
                    }
                    LocalRemoteManager.VUCount++

                    break
                case "sendVideoImage":
                    // handled by stage
                    break
                case "sendRemoteVideoImage":
                    // handled by stage
                    break

                // handled by Device or Agent
                case "setAudioDeviceInfo":
                    break
                case "setNICOptions":
                    break

                case "setVideoDeviceInfo":
                    break

                case "setMIDIInputDeviceInfo":
                    break

                case "setMIDIOutputDeviceInfo":
                    break

                case "applySettings":
                    break

                case "applySettingsV240124":
                    break

                case "standalone":
                    break

                case "crashIndicator":
                    fNOP()
                    break

                case "soundCardStatus":
                    break

                case "setLocalSoundLevel":
                case "setLocalSoundLevelV1":
                    break

                // these 3 handled by stage...
                case "tellLatency":
                    break
                case "tellDropout":
                    break
                case "setJitterBuffer":
                    break

                case "getUUID":
                    const debUUID: string = eventData.UUID
                    fNOP()
                    break

                case "streamIsHere":
                    {
                        /* {"ID":"0","IP":"144.76.81.210","channelCount":"2","decodeFactor":"16",
                        "frameSize":"256","port":"5401","type":"streamIsHere"} */
                        LocalRemoteManager.logger.info(
                            `streamIsHere ${eventData.IP}:${eventData.port}`
                        )
                        //we can use this to verify a remote conn...
                        const IP: string = eventData.IP
                        const portTCP: string = eventData.port
                        LocalRemoteManager.verifyRemote(IP, portTCP)
                    }
                    break

                case "streamIsGone":
                    LocalRemoteManager.logger.info(
                        `streamIsGone ${eventData.IP}:${eventData.port}`
                    )
                    // 240417 - AC says dont use this to disconnect...
                    const DO_NOT_USE_240417_ON: boolean = false
                    if (DO_NOT_USE_240417_ON) {
                        /* {"IP":"127.0.0.1","data1":"3","port":"50050","type":"streamIsGone"} */
                        LocalRemoteManager.logger.todo(
                            `streamIsGone ${eventData.IP}:${eventData.port}`
                        )
                        //we can use this to modify a remote conn status ...
                        const IP: string = eventData.IP
                        const portTCP = eventData.port
                        const remote: RemoteInfo | null =
                            LocalRemoteManager.findRemoteFromPortSkt(
                                IP,
                                portTCP
                            )
                        // WIP !!!! - not checked the overall logic on this...
                        if (remote != null) {
                            if (
                                remote.remoteConnectState !=
                                RemoteConnectState.disconnected
                            ) {
                                // stop it
                                LocalRemoteManager.handleDisconnect(
                                    remote.remoteTCPAddr,
                                    remote.remoteTCPPort
                                )
                            } else {
                                LocalRemoteManager.notify(
                                    RemoteChangeEventType.Remove,
                                    remote
                                )
                            }
                            //LocalRemoteManager.notify(RemoteChangeEventType.Readyness, remote);
                        }
                        // but we should await actual disconnect message from server to get rid of it?
                    }
                    break

                case "traceroute":
                    /*
                    {"traceIP":"144.76.81.210","tracePort":"5401","traceResult":"GEOLOCATION: {\n  \"ip\": \"144.76.81.210\",\n  \"hostname\": \"mx00.ionos.de\",\n  \"city\": \"Falkensee\",\n  \"region\": \"Brandenburg\",\n  \"country\": \"DE\",\n  \"loc\": \"52.5601,13.0927\",\n  \"org\": \"AS24940 Hetzner Online GmbH\",\n  \"postal\": \"14612\",\n  \"timezone\": \"Europe/Berlin\",\n  \"readme\": \"https://ipinfo.io/missingauth\"\n}TRACEROUTE:  1  192.168.0.1 (192.168.0.1)  9.710 ms  12.649 ms  6.554 ms\n; 2  * * *\n; 3  gate-core-2b-ae81-650.network.virginmedia.net (62.252.170.233)  19.785 ms  20.053 ms  21.293 ms;\n; 4  * * *\n; 5  * * *\n; 6  tele-ic-6-ae2-0.network.virginmedia.net (62.253.174.86)  30.427 ms  33.491 ms  36.182 ms\n; 7  ae19-0.lon10.core-backbone.com (5.56.20.73)  31.986 ms  45.671 ms  29.870 ms\n; 8  ae1-2014.nbg40.core-backbone.com (81.95.15.206)  42.315 ms  48.982 ms  61.096 ms\n; 9  core-backbone.hetzner.com (5.56.20.254)  44.303 ms  42.687 ms  74.094 ms\n;","type":"traceroute"}
                    */
                    fNOP()
                    break

                case undefined:
                    fNOP()
                    break

                default:
                    LocalRemoteManager.logger.todo(
                        `Unhandled incoming Agent message ${eventData.type}: ${event.data}`
                    )
                    break
            }
        },
    }

    // server https://app.bonzamusic.com/ or soundjack.eu
    bonzaEventListener: SocketEventListener = {
        handleSocketEvent(event: SocketEvent) {
            if (event.type == "open") {
                // login handled in open listener in Stage for auth.user
                // if(event.type == "open") {
                //     LocalRemoteManager.setOpen();
                //     LocalRemoteManager.login(auth.user);
                // }

                // start heartbeat
                if (LocalRemoteManager.idt1 == null) {
                    LocalRemoteManager.idt1 = setTimeout(
                        LocalRemoteManager.timedEventFunction.bind(
                            LocalRemoteManager
                        ),
                        5000
                    )
                }
            } else if (event.type == "message") {
                fNOP()
            } else if (event.type == "close" || event.type == "error") {
                if (LocalRemoteManager.idt1 != 0) {
                    clearTimeout(LocalRemoteManager.idt1)
                    LocalRemoteManager.idt1 = 0
                }
            }
        },
    }

    bonzaMessageListener: MessageEventListener = {
        handleMessageEvent(event: MessageEvent, data: any | null | undefined) {
            if (!data || !data.type) return
            switch (data.type) {
                case "user":
                    fNOP()
                    break
                case "message":
                    fNOP()
                    break
                case "crashIndicator":
                    LocalRemoteManager.logger.warn(
                        "Surely this comes from BA not the server???"
                    )
                    break

                case "system":
                    {
                        let postHandleLogLevel:
                            | LogLevel.None
                            | LogLevel.Warn
                            | LogLevel.Trivial = LogLevel.None
                        // for trivials etc at END of this switch
                        // so for important events that may create other logs, log explicitly & dont set postHandleLogLevel...
                        if (data.action) {
                            switch (data.action) {
                                case "Add user":
                                    {
                                        // this is where we RECEIVE details of a remote thats trying to connect *to us*: (if
                                        // engineIP etc ARENT "N/A" - if they ARE N/A its just to tell us their userID)

                                        // if data validated we do a startNetStream to start sending to them, & await relev "Disconnect"
                                        // it they disconnect from US.
                                        // If we disconnect from Them we should SEND a "Disconnect" message to Bonza which should
                                        // cause IT (the server) to send it on to our remote, see ...

                                        // NB if WE instigated the connection we can set the state from trying to connected
                                        // else we still set state to connected & should update the remote IP etc in a new remote panel
                                        // and add the remote to our local (or server) database
                                        // BUT - while on SJ need to watch out for other users???
                                        const fd: Add_UserMessage = {
                                            // filtered data ie only that which is relevant...
                                            remotePort: data.remotePort,
                                            remoteIP: data.remoteIP,
                                            remoteID: data.remoteID,
                                            interfaceIP: data.interfaceIP,
                                            engineIP: data.engineIP,
                                            remoteUDPPort: data.remoteUDPPort,
                                            remoteUDPPort2: data.remoteUDPPort2,
                                            NAT: data.NAT,
                                            remoteName: data.remoteName,
                                        }
                                        if (data.systemID) {
                                            fNOP() // this isnt supposed to be part of this message? poss spurious
                                        }
                                        LocalRemoteManager.logger.info(
                                            `RM: system msg : ${data.action}, ${JSON.stringify(fd)}`
                                        )
                                        LocalRemoteManager.handleAddUser(fd)
                                    }
                                    break

                                case "Disconnect":
                                    {
                                        // our remote has sent a Disconnect to the server & server has sent a Disconnect to us!
                                        // we should stop our stream to that remote,
                                        // and we should also should delete the entry.
                                        const fd = {
                                            // filtered data ie only that which is relevant...
                                            remoteIP: data.remoteIP,
                                            remotePort: data.remotePort,
                                            ownIP: data.ownIP,
                                            ownPort: data.ownPort,
                                        }
                                        LocalRemoteManager.logger.info(
                                            `RM: system msg : ${data.action}, ${JSON.stringify(fd)}`
                                        )

                                        const debResult: DisconnectResult =
                                            LocalRemoteManager.handleDisconnect(
                                                fd.remoteIP,
                                                fd.remotePort
                                            )
                                        if (debResult == DisconnectResult.OK)
                                            fNOP()
                                        else if (
                                            debResult ==
                                            DisconnectResult.NotConnected
                                        )
                                            fNOP()
                                        else if (
                                            debResult ==
                                            DisconnectResult.NotPresent
                                        )
                                            fNOP()
                                        else if (
                                            debResult ==
                                            DisconnectResult.BadParams
                                        )
                                            fNOP()
                                    }
                                    break

                                case "Delete user":
                                    {
                                        const fd = {
                                            // filtered data ie only that which is relevant...
                                            remoteIP: data.remoteIP,
                                            remotePort: data.remotePort,
                                            message: data.message,
                                        }
                                        LocalRemoteManager.logger.info(
                                            `RM: system msg : ${data.action}, ${JSON.stringify(fd)}`
                                        )
                                        // AC says this gets sent when a user logs off
                                        let isLogout: boolean = false
                                        if (
                                            fd.message &&
                                            (fd.message as string).startsWith(
                                                "Logout:"
                                            )
                                        ) {
                                            isLogout = true
                                        }

                                        // if connected, should stop our NetStream, then delete the entry...

                                        // !!! 240424 - this may cause unrecoverable problem if we delete
                                        // our remoteInfo & player but the actual remote is still
                                        // sending for some reason (eg it DIDNT receive corresponding msg)... (happened twice today)

                                        const delete_causes_disconnect = true
                                        if (delete_causes_disconnect) {
                                            const isInternal: boolean = false
                                            const debresult: DisconnectResult =
                                                LocalRemoteManager.handleDisconnect(
                                                    fd.remoteIP,
                                                    fd.remotePort,
                                                    isInternal,
                                                    isLogout
                                                ) // this deletes it as well at pres
                                            fNOP()
                                        }
                                    }
                                    break

                                case "Update UDP port":
                                    {
                                        const fd = {
                                            // filtered data ie only that which is relevant...
                                            engineIP: data.engineIP,
                                            remoteUDPPort: data.remoteUDPPort,
                                            remoteUDPPort2: data.remoteUDPPort2,
                                            NAT: data.NAT,
                                            interfaceIP: data.interfaceIP,
                                            OS: data.OS,
                                            remoteID: data.remoteID,
                                        }
                                        LocalRemoteManager.logger.info(
                                            `RM: system msg : ${data.action}, ${JSON.stringify(fd)}`
                                        )
                                        LocalRemoteManager.handleUpdateUDPPortData(
                                            fd
                                        )
                                        fNOP()
                                    }
                                    break

                                case "SJTG_no_such_user":
                                    // here's where we get notified if attempted connect to invalid user
                                    // should set state from trying to failed
                                    if (
                                        data.remoteName &&
                                        data.remoteName.length
                                    ) {
                                        // 240606+
                                        LocalRemoteManager.logger.error(
                                            `RM: system msg : ${data.action}, ${data.remoteName}`
                                        )
                                        LocalRemoteManager.setNoSuchUser(
                                            data.remoteName
                                        )
                                    } else {
                                        LocalRemoteManager.logger.error(
                                            `RM: system msg : ${data.action}`
                                        )
                                        // unfortunately no extra data eg the name of the no-such-user...
                                        LocalRemoteManager.setNoSuchUser("")
                                    }

                                    break

                                case "SJTG_name_exists":
                                    LocalRemoteManager.logger.error(
                                        `RM: system msg : ${data.action}`
                                    )
                                    // unfortunately no extra data eg the name of existing name...
                                    LocalRemoteManager.setLoginFailed()
                                    break

                                case "KILL":
                                    LocalRemoteManager.logger.error(
                                        `RM: system msg : KILL`
                                    )
                                    // we should kill all connections TODO!!!
                                    LocalRemoteManager.handleKill()
                                    break

                                /// UNUSED ///
                                case "GROUP POSITION":
                                    {
                                        postHandleLogLevel = LogLevel.Trivial
                                        /* { eg
                                        "action":"GROUP POSITION",
                                        "groupPosition":"1"
                                        <rest garbage>
                                     */
                                        const fd = {
                                            action: data.action,
                                            groupPosition: data.groupPosition,
                                        }
                                        data = fd
                                    }
                                    break

                                case "ROOM STATUS UPDATE":
                                    {
                                        postHandleLogLevel = LogLevel.Trivial
                                        /* eg
                                        "action":"ROOM STATUS UPDATE",
                                        "roomName":"SJL",
                                        "password":"NONE",
                                        "remoteID":"0",
                                        "remoteIP":"82.41.0.201",
                                        "remotePort":"53082",
                                        <rest garbage>
                                    */
                                        const fd = {
                                            action: data.action,
                                            roomName: data.roomName,
                                            password: data.password,
                                            remoteID: data.remoteID,
                                            remoteIP: data.remoteIP,
                                            remotePort: data.remotePort,
                                        }
                                        data = fd
                                    }
                                    break

                                case "HEARTBEAT":
                                    LocalRemoteManager.logger.trivial(
                                        `RM: got HEARTBEAT`
                                    )
                                    break

                                case "Update App Status":
                                    break

                                case "Update Audio Status":
                                    break

                                default:
                                    // unknown so warn on it...
                                    postHandleLogLevel = LogLevel.Warn
                                    break
                            } // eo switch

                            switch (postHandleLogLevel) {
                                case LogLevel.None:
                                    break
                                case LogLevel.Warn:
                                    LocalRemoteManager.logger.warn(
                                        `RM: system msg : ${data.action}, ${JSON.stringify(data)}`
                                    )
                                    break
                                default:
                                    LocalRemoteManager.logger.trivial(
                                        `RM: system msg : ${data.action}, ${JSON.stringify(data)}`
                                    )
                                    break
                            }
                        } // eo if(data.action)
                    } // 'system'
                    break
                default:
                    break
            } // eo switch
        },
    }

    private verifyRemote(IP: string, portTCP: string) {
        const remote: RemoteInfo | null = this.findRemoteFromPortSkt(
            IP,
            portTCP
        )
        if (remote != null) {
            if (remote.remoteConnectState != RemoteConnectState.verified) {
                remote.remoteConnectState = RemoteConnectState.verified
                this.notify(RemoteChangeEventType.Readyness, remote)
                //LocalRemoteManager.notify(RemoteChangeEventType.Remove, remote);
                //LocalRemoteManager.notify(RemoteChangeEventType.Add, remote);

                // if -> verified see if had a previous val for name and no port?
                let val: number = this.getRemoteVolumeForIPP(
                    remote.remoteName,
                    ""
                )
                let tryOtherWayRound: boolean = false
                if (val != SliderMinMax.default) {
                    this.setRemoteVolumeForIPP(IP, portTCP, val)
                } else {
                    tryOtherWayRound = true
                }
                val = this.getRemotePanForIPP(remote.remoteName, "")
                if (val != KnobMinMax.default) {
                    this.setRemotePanForIPP(IP, portTCP, val)
                } else {
                    tryOtherWayRound = true
                }
                if (tryOtherWayRound) {
                    val = this.getRemoteVolumeForIPP(IP, portTCP)
                    if (val != SliderMinMax.default) {
                        this.setRemoteVolumeForIPP(remote.remoteName, "", val)
                    }
                    val = this.getRemotePanForIPP(IP, portTCP)
                    if (val != KnobMinMax.default) {
                        this.setRemotePanForIPP(remote.remoteName, "", val)
                    }
                }
            } else if (
                remote.remoteConnectState == RemoteConnectState.verified
            ) {
                fNOP() // OK already
            }
        }
    }

    private checkRISSStatus() {
        if (LocalDevice.initState == AudioISStates.AISS_Confirmed) {
            if ((this.initState & RISSFlags.RISS_AudioRunning) == 0) {
                this.initState |= RISSFlags.RISS_AudioRunning
            }
        } else {
            if (
                (this.initState & RISSFlags.RISS_AudioRunning) ==
                RISSFlags.RISS_AudioRunning
            ) {
                this.initState &= ~RISSFlags.RISS_AudioRunning
            }
        }
        if (RISS_isReady(this.initState)) {
            this.initState |= RISSFlags.RISS_Ready
            Bonza.setGlobalRemoteConnectState(true)
        } else {
            this.initState &= ~RISSFlags.RISS_Ready
            Bonza.setGlobalRemoteConnectState(false)
        }
        /// !!! - so how to enable/disable the 'connect' button dep on above ???
    }

    public getRISSErrors(): string {
        let str = ""
        // 240427 fix
        // if ((this.initState & ~RISSFlags.RISS_LoggedIn)==0 || (this.initState | RISSFlags.RISS_LoginFailed)) {
        //     str += 'Not logged into Bonza ';
        // }
        // if ((this.initState & ~RISSFlags.RISS_AudioRunning) == 0) {
        //     str += 'Audio Not Ready ';
        // }
        if (
            (this.initState & RISSFlags.RISS_LoggedIn) == 0 ||
            this.initState & RISSFlags.RISS_LoginFailed
        ) {
            str += "Not logged into Bonza"
        } else {
            fNOP() // OK - but how?
        }
        if (Agent.readyState !== ReadyState.Open) {
            if (str.length > 0) {
                str += ", "
            }
            str += "Agent Not Ready"
        }
        if ((this.initState & RISSFlags.RISS_AudioRunning) == 0) {
            if (str.length > 0) {
                str += ", "
            }
            str += "Audio Not Ready"
        }
        return str
    }

    private notify(eventType: RemoteChangeEventType, info: RemoteInfo) {
        this._listeners.forEach((listener) => {
            listener.handleRemoteChange(this, eventType, info)
        })
    }

    // helper funcs adapted directly from soundjack for now!!!

    private portInformationToPortal(
        localIP: string,
        localPort: string,
        localPort2: string,
        NAT: string,
        interfaceIP: string,
        OS: string
    ): boolean {
        if (!(this.initState & RISSFlags.RISS_SocketOpen)) {
            return false
        }
        Bonza.send(
            new UDPPortUpdateMessage(
                localIP,
                localPort,
                localPort2,
                NAT,
                interfaceIP,
                OS
            )
        )
        const userIDs: UserIDs | null = BonzaService.getUserIDs()
        if (userIDs && userIDs.userIDSJ) {
            Bonza.send(new ROOMMessage("Bonza", "NONE", userIDs.userIDSJ))
        } else {
            this.logger.error(
                `portInformationToPortal had bad userIDSJ - aborting`
            )
            return false
        }
        return true
    }

    /*
            var streamIP;
            var streamPort;
            if ( userIP == array[index].IP ){
                streamIP = array[index].interfaceIP;
                streamPort = 50050;
            } else{
                streamIP = array[index].IP;
                streamPort = array[index].portUDP;
            }
            frames[0].startNetStream(streamIP,streamPort,userID,array[index].ID,array[index].portUDP2,array[index].IP,array[index].port);
    */

    private startNetStream4R(r: RemoteInfo): boolean {
        const s = this.workingData.selfInfo
        let streamIP
        let streamPort

        const SHasExtIP = isIP(s.externalIP)
        const SHasIntIP = isIP(s.internalIP)
        const RHasExtIP = isIP(r.engineIP)
        const RHasIntIP = isIP(r.interfaceIP)

        if (!SHasExtIP || !RHasExtIP) {
            if (!SHasExtIP) {
                this.logger.warn(`startNetStream4R self had no extIP`)
            }
            if (!RHasExtIP) {
                this.logger.warn(`startNetStream4R remote had no extIP`)
            }
            if (!SHasExtIP && !RHasExtIP && !RHasIntIP) {
                this.logger.error(
                    `RM1562: startNetStream4R no extIPs AND remote had no intIP`
                )
                return false
            }
        }

        if (s.externalIP == r.engineIP && RHasIntIP) {
            if (!SHasIntIP) {
                this.logger.warn(
                    `RM 1569: startNetStream4R ext ips matched but self has no interfaceIP`
                )
                // not an error as such but implies the remote wouldnt be able to send to us
            }
            streamIP = r.interfaceIP
            streamPort = "50050"
            r.connectedOnLan = true
        } else {
            if (s.externalIP == r.engineIP && !RHasIntIP) {
                this.logger.warn(
                    `startNetStream4R ext ips matched but remote had no interfaceIP - using external IPs`
                )
            }
            if (r.ownUDPPort.length == 0) {
                this.logger.error(`startNetStream4R remote had no UDP port`)
                return false
            } else {
                const udpnum = Number(r.ownUDPPort)
                if (!udpnum || udpnum < 0 || udpnum > 65535) {
                    this.logger.error(
                        `startNetStream4R remote had invalid UDP port ${udpnum}`
                    )
                    return false
                }
            }
            streamIP = r.engineIP
            streamPort = r.ownUDPPort
            r.connectedOnLan = false
        }

        const remoteUserID: number = Number(r.remoteUserID)
        // old ver no same-lan check: this._startNetStream(r.engineIP, r.ownUDPPort, Number(s.userID), Number(r.userID), r.ownUDPPort2, r.remoteTCPAddr, r.remoteTCPPort);
        const userIDs: UserIDs | null = BonzaService.getUserIDs()
        if (userIDs && userIDs.userIDSJ) {
            if (remoteUserID < SJ_USERID_OFFSET) {
                this.logger.warn(`startNetStream4R using ${remoteUserID}`)
            }
            this._startNetStream(
                streamIP,
                streamPort,
                userIDs.userIDSJ,
                remoteUserID,
                r.ownUDPPort2,
                r.remoteTCPAddr,
                r.remoteTCPPort
            )
        } else {
            this.logger.warn(`startNetStream4R had bad userIDSJ - aborting`)
            return false
        }
        return true
    }

    private _startNetStream(
        internalIP: string,
        internalPort: string,
        ownID: number,
        remoteSenderID: number,
        remotePortwithNAT: string,
        remoteSocketIP: string,
        remotePortTCP: string
    ) {
        // var msg = {
        //     type: "startStream",
        //     IP: internalIP,
        //     port: internalPort,
        //     ownID: ownID,
        //     remoteSenderID: remoteSenderID,
        //     remoteNAT: remoteNAT,
        //     remoteSocketIP: remoteSocketIP,
        //     remotePortTCP: remotePortTCP
        // };
        // websocketSendStartStream(IP,port,ownID,remoteSenderID,remoteNAT,remoteSocketIP,remotePortTCP);

        const params: RemoteInfoData = new RemoteInfoData()
        params.IP = internalIP
        params.port = internalPort
        params.ownID = ownID
        params.remoteSenderID = remoteSenderID
        params.remoteNAT = remotePortwithNAT // SJ takes substring to extract extNAT from "pppp (NAT)"
        params.remoteSocketIP = remoteSocketIP
        params.remotePortTCP = remotePortTCP

        this.logger.info(`RM:StartStreamMessage(${JSON.stringify(params)}`)

        const msg = new StartStreamMessage(params)
        Agent.send(msg)
    }

    private sendHeartbeat() {
        Bonza.send(new HEARTBEATMessage())
    }

    // e o temp helper funcs!!!

    // private getIPForName( name : string) : IPAndPort {
    //     const info = new IPAndPort();
    //     this.logger.todo("getIPForName TODO");
    //     // scan this.workingData.remotes for name
    //     return info;
    // }

    public attemptConnectToName(remotename: string): boolean {
        if (isLname(remotename)) {
            const msg: StartStreamMessage = new StartStreamMessage() // (no param signifies use default (localhost));
            Agent.send(msg)
            this.logger.info(`LHStart ${msg.toString()}`)
            return true
        }
        if (isMname(remotename)) {
            const MirrorParams = new MirrorLH_SSP()
            const msg = new StartStreamMessage(MirrorParams)
            Agent.send(msg)
            this.logger.info(`MirrorStart ${msg.toString()}`)
            return true
        }

        const si: SelfInfo = this.workingData.selfInfo
        if (this.workingData.currentRemoteInProgressName != remotename) {
            this.workingData.currentRemoteInProgressName = remotename
        }
        const isSelfDataValid: boolean = si.isValid()
        if (isSelfDataValid) {
            //this.logger.todo(`RMJ:718 To check w Alex UDP Port 2 ${si.userUDPPort2} is OK to send or not ALSO userID?`);
            const selfName: string | null | undefined = BonzaService.user?.name
            const selfUserId: number | null | undefined =
                BonzaService.getUserIDs()?.userIDSJ
            if (selfName && selfUserId && !isNaN(selfUserId)) {
                /// TODO - we *could* also check user to connect to is feasible by saving a database
                // of previous add user (user list update) messages that have not had a delete user...
                // that would ensure we dont get an add user fail due to bad data later...

                const msg: SJTG_addMessage = new SJTG_addMessage(
                    remotename,
                    selfName,
                    si.userUDPPort,
                    si.userUDPPort2,
                    si.internalIP,
                    si.externalIP,
                    selfUserId
                )
                //remoteName, userName, userUDPPort, userUDPPort2, interfaceIP, engineIP, "0"
                Bonza.send(msg)
            } else {
                this.logger.error(
                    `Attempting to connect to ${remotename} but my data isnt valid`
                )
                return false
            }
            //this.addPotentialRemote(remotename); !! NO we should have already done this
        } else {
            this.logger.error(
                `Attempting to connect to ${remotename} but my data isnt valid`
            )
            return false
        }
        // remote (if logged in) should receive an 'Add user' message telling it to start streaming to us...
        // WE should also receive and 'Add User' message telling us to start streaming to them...
        // When that arrives we can add the new remote to our workingData.remotes list.
        // (until then we dont know the remote IP or port so disconnect wont have any data to use)
        // also we start our own streaming by setting up a new StartStreamMessage with the provided data.

        // TODO: why isnt the server sending us anything?
        return true
    }

    private delRemoteFromArrayByIPandPort(r: RemoteInfo): boolean {
        // NOTE - this ONLY looks for remote match by IP and port - not name...
        const oldLen = this.workingData.remotes.length
        this.workingData.remotes = this.workingData.remotes.filter(
            (item) =>
                !(
                    item.engineIP == r.engineIP &&
                    item.remoteTCPPort == r.remoteTCPPort
                )
        )
        const newLen = this.workingData.remotes.length
        if (oldLen != newLen + 1) {
            this.logger.error(
                `delRemoteFromArray failed to delete a matching remote ${r.remoteName}`
            )
            return false
        }
        return true
    }

    private delRemoteFromArrayByName(r: RemoteInfo): boolean {
        // NOTE - this ONLY looks for remote match by name...
        const oldLen = this.workingData.remotes.length
        this.workingData.remotes = this.workingData.remotes.filter(
            (item) => !(item.remoteName == r.remoteName)
        )
        const newLen = this.workingData.remotes.length
        if (oldLen != newLen + 1) {
            this.logger.error(
                `delRemoteFromArray failed to delete a matching remote ${r.remoteName}`
            )
            return false
        }
        return true
    }

    private addRemoteToArray(r: RemoteInfo) {
        this.workingData.remotes.push(r)
    }

    // <KB 240415 - this is hard to understand logic wise - to rehash to be more straightforward...>
    // < it also includes code to mitigate IF RemoteManager's remotes array might get out of step with
    // the parallel GUI array maintained via notify(RemoteChangeEventType) ... if we know these
    // wont ever get out of step then some of the code could be removed>
    public guiReqDisconnectFromName(remotename: string): boolean {
        // get remote if any but first check for localhost or mirror names...
        let remote: RemoteInfo | null = this.findRemoteFromName(remotename)
        if (isLname(remotename)) {
            const msg = new LHStopStreamMessage()
            this.logger.info(`LHStop ${msg.toString()}`)
            Agent.send(msg)
            if (remote) {
                // actually at pres localhost not put in array...
                this.logger.warn(`RM1393: unexpected localhost in array`)
                this.delRemoteFromArrayByIPandPort(remote)
            }
            return true
        }
        if (isMname(remotename)) {
            const msg = new MirrorStopStreamMessage()
            this.logger.info(`MirrorStop ${msg.toString()}`)
            Agent.send(msg)
            if (remote) {
                // actually at pres mirror not put in array...
                this.logger.warn(`RM1404: unexpected mirror in array`)
                this.delRemoteFromArrayByIPandPort(remote)
            }
            return true
        }
        // OK we may or may not have a remote, and may or may not have a name, (but deffo not localhost or mirror)
        // presumably if no name we wouldnt find a remote, but not double checked that edge-case scenario.
        // the below code includes experimental section JIC that could occur (but disabled at pres)
        const namelen = remotename.length
        if (namelen == 0) {
            // no name: so just del last added remote if pres as a fallback
            const NRemotes: number = this.workingData.remotes.length
            if (NRemotes > 0) {
                const lastIndex: number = NRemotes - 1
                // load remote with last in array for next step...
                remote = this.workingData.remotes[lastIndex]
                remotename = remote.remoteName
                //
            } else {
                // on stop with empty name AND no remotes
                this.logger.trivial(
                    `RM:1426 on stop with empty name AND no remotes`
                )
                const EXPERIMENTAL = false
                if (EXPERIMENTAL) {
                    // we *may* need to purge any remaining remotes in GUI if we're not in sync
                    // & at pres - no way to query how many its got???
                    this.logger.todo(`RM:1408 a) del all (experimental)`)
                    const dummy: RemoteInfo = new RemoteInfo(-1)
                    this.notify(RemoteChangeEventType.RemoveAll, dummy)
                    //const msg = new KILLMessage(this.workingData.selfInfo.externalIP, this.workingData.selfInfo.externalPort ); // umm - what IP and port do we need ???
                    //Bonza.send(msg);
                    // hmm - poss this is also zapping the socket connection? poss killing the server connection? - so dont do it
                    // until checked with Alex!!!
                } else {
                    // OK - probably user clicking stop - even if there's nothing to stop...
                    // just ignore - if we get sync problems between the arrays
                    // may have to revisit this & poss enable above block
                }
                return false
            }
        }
        if (remote != null) {
            // either name matched, or empty name caused load last in array
            // or poss remote was a new unknown (trying) name so will be just about empty & not connected...
            if (remote.remoteConnectState != RemoteConnectState.disconnected) {
                // ie trying, connected or verified...
                const dm = new DisconnectMessage(
                    remote.engineIP,
                    remote.remoteTCPPort,
                    remote.remoteName
                )
                Bonza.send(dm)
                // this should result in the remote receiving its own "Disconnect" message

                // now we should stop our stream to the remote & delete its entry...
                // v similar to what handleDisconnect() does, so use that...
                const isInternal: boolean = true
                this.handleDisconnect(
                    remote.engineIP,
                    remote.remoteTCPPort.toString(),
                    isInternal
                )
                return true
            } else {
                // <insert 240415 - below block looks wrong or unfinished - comment it out>
                // {
                //     this.delRemoteFromArray( remote );
                //     this.logger.todo(`RM:1402 b) not sure if relev port is IN selfInfo TBC`);
                //     const dummy : RemoteInfo = new RemoteInfo(-1);
                //     this.notify(RemoteChangeEventType.RemoveAll, dummy );
                //     const msg = new KILLMessage(this.workingData.selfInfo.externalIP, this.workingData.selfInfo.externalPort );
                //     Bonza.send(msg);
                //     return false;
                // }
                // <insert 240415 - we have a disconnected remote... this shouldnt happen but may do if timing/race conditions???>
                this.delRemoteFromArrayByName(remote)
                this.notify(RemoteChangeEventType.Remove, remote) // this already ONLY checks the name (not IP or port)
                return true
            }
        } else {
            // remote was null but namelen wasnt 0
            // - no remote in array to match ...
            // this can occur if click disconnect in test tab of connections but there wasnt a previous corresponding connect
            fNOP()
            // 240703 - DONT delete all - just ignore... well can try notifying wrt name JIC?
            const dummy: RemoteInfo = new RemoteInfo(-1)
            dummy.remoteName = remotename
            // this.notify(RemoteChangeEventType.RemoveAll, dummy );
            this.notify(RemoteChangeEventType.Remove, dummy)

            // hmm - sometimes get an 'old' name still in players??? so the Remove fails?
            // KB 240705
        }
        return false
    }

    addPotentialRemote(remotename: string, isTest: boolean = false): boolean {
        // this is called when GUI chooses some 'name' to connect to

        this.checkRISSStatus()
        const initstate = this.initState
        if (
            !(initstate & RISSFlags.RISS_Ready) ||
            initstate & RISSFlags.RISS_Error ||
            initstate & RISSFlags.RISS_LoginFailed
        ) {
            const errstr = LocalRemoteManager.getRISSErrors()
            this.logger.error(
                `Attempting to connect to ${remotename} but we're not ready yet: ${errstr}`
            )
            return false
        }
        if (!BonzaService.user) {
            this.logger.error(
                `Attempting to connect to ${remotename} but we have no user yet`
            )
            return false
            // shouldnt even be able to attempt connect if no self user yet,
            // ALSO - above test should have already failed
        }
        if (!remotename.length) {
            this.logger.error(`Attempting to connect to empty remotename`)
            return false
            // shouldnt even have got this far - previous steps ought to have already filtered this out?
        }

        const existing = this.findRemoteFromName(remotename)
        if (existing != null) {
            this.logger.warn(`RM:1314 Remote already present for ${remotename}`)
            return false
        }
        if (isLorMname(remotename)) {
            this.attemptConnectToName(remotename)
            return true
        }
        if (remotename === BonzaService.user.name) {
            // cant connect to ourself (except by localhost explicitly)
            return false
        }
        const thisRemoteIndex = this.workingData.remotes.length
        const remote: RemoteInfo = new RemoteInfo(thisRemoteIndex)
        remote.remoteName = remotename
        remote.remoteConnectState = RemoteConnectState.trying
        if (isTest) {
            remote.isTest = true
        }
        //this.workingData.remotes.push(remote);
        this.addRemoteToArray(remote)
        this.notify(RemoteChangeEventType.Add, remote)
        // now attempt connect
        this.attemptConnectToName(remotename)
        return true
    }

    public setOpen() {
        this.initState |= RISSFlags.RISS_SocketOpen
    }

    public tempZBPHandleChange(
        toZone: PanZoneModel | null,
        player: PlayerViewGeneric | null,
        IP: string | null,
        port: string | null,
        panpn100s: string | null
    ) {
        let remote: RemoteInfo | null
        let isZone: boolean
        if (player && toZone) {
            if (IP || port || panpn100s) {
                this.logger.error(`tempZBPHandleZoneChange: too many params`)
                return
            }
            remote = this.findRemoteFromName(player.name)
            isZone = true
        } else if (IP && port && panpn100s) {
            remote = this.findRemoteFromPortSkt(IP, port)
            isZone = false
        } else {
            this.logger.error(
                `tempZBPHandleZoneChange: invalid param combination`
            )
            // we want (zone, player, null, null, null) OR (null, null, IP, port, val)
            return
        }
        if (!remote && isZone && player) {
            this.logger.error(
                `tempZBPHandleZoneChange: could not find player ${player.name}`
            )
            return
        } else if (!remote && !isZone) {
            this.logger.error(
                `tempZBPHandleZoneChange: could not find IP ${IP}:${port}`
            )
            return
        }
        if (toZone && remote) {
            const previousZoneId = remote.zoneid
            if (previousZoneId == toZone.id) {
                return // no change
            }
            if (!remote.remoteTCPAddr.length || !remote.remoteTCPPort.length) {
                return // remote probably just added by name & not got its port & IP yet...
            }
            const panValue = toZone.id === 0 ? -100 : toZone.id === 2 ? 100 : 0
            Agent.send(
                new SetRemotePanMessage(
                    remote.remoteTCPAddr,
                    remote.remoteTCPPort,
                    panValue.toString()
                )
            )
        } else if (remote && IP && port && panpn100s) {
            const previousPan = remote.panpn100s
            if (previousPan == panpn100s) {
                return // no change
            }
            const panValue =
                remote.zoneid === 0 ? -100 : remote.zoneid === 2 ? 100 : 0
            Agent.send(
                new SetRemotePanMessage(
                    remote.remoteTCPAddr,
                    remote.remoteTCPPort,
                    panValue.toString()
                )
            )
        } else {
            fNOP()
        }
    } // eo tempZBPHandleChange
}
