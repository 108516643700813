import { data } from "autoprefixer"
import { RTInfo } from "@/types/PlayerView"

interface NwkInfoSubscriber {
    receive: (info: RTInfo) => void
}

interface NwkInfoData {
    data: string
    timestamp: number
}

export const NwkLatency = {
    Excellent: 20,
    VeryGood: 25,
    Good: 31,
    Fair: 36,
    Poor: 41,
    Terrible: 60,
    Unusable: 9999999,
}

export class NwkInfoService {
    private _info: Record<string, NwkInfoData> = {}
    private _subscribers: Record<string, NwkInfoSubscriber> = {}

    public subscribe(name: string, subscriber: NwkInfoSubscriber) {
        this._subscribers[name] = subscriber
    }

    public unsubscribe(name: string) {
        delete this._subscribers[name]
    }

    public set(name: string, info: RTInfo) {
        this._subscribers[name]?.receive(info)
    }
}

export const NwkInfo = new NwkInfoService()
