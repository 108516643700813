/**
 * The AgentService is intended as the place where all communication with the app, running locally on port 50050,
 * takes place.
 *
 * Events from the socket are communicated through events, dispatched by the AgentService as a CustomEvent, whose
 * 'detail' member is one of the events declared here:
 *   - SocketEvent when the socket event is a generic event (when opened or when there's an error)
 *   - SocketCloseEvent when the socket event os a CloseEvent, proving a numeric code and the reason
 *   - SocketMessageEvent when the socket sends a message, where data can be number, string or binary
 */

import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
    WebSocketService,
} from "@/types/WebSocket"
import LoggerService from "@/services/LoggerService"
import { AgentMessage } from "@/types/AppMessage"
import { fNOP } from "@/types/Device"
import { ServiceLogHelper } from "@/services/helpers/ServiceLogHelper"
import { KnobMinMax, SliderMinMax } from "@/components/AudioControlLocal"

/**
 * A convenient way for classes and components to access agent functionality, without having to import the AgentService
 * class (which only needs to be instantiated once, and whose single instance is exported as 'Agent').
 */
export interface IAgentService {
    readyState: ReadyState
}

/**
 * Here's where the work is done, and it can of course be farmed out to other classes, functions, etc.
 *
 * The constructor accepts a port number, to allow for the scenario where this might need to be varied (don't know if
 * that's ever the case).
 */
export class AgentService implements IAgentService, WebSocketService {
    private static _port: number = 50050

    public static getAgentPort(): number {
        return AgentService._port
    }

    private socket: null | WebSocket = null
    private _socketEventListeners: Array<SocketEventListener> = []
    private _messageEventListeners: Array<MessageEventListener> = []

    private logger: LoggerService = new LoggerService("AgentService")
    private serviceLog: ServiceLogHelper = new ServiceLogHelper("AgentService")

    private open(port: number = AgentService._port) {
        if (port != AgentService._port) {
            AgentService._port = port
            this.logger.info(`Port was set to ${port}`)
        } else if (this.readyState == ReadyState.Open) {
            this.logger.info(
                `Ignoring 'open' call because the socket is already open on port ${port}`
            )
        }

        const url = `ws://localhost:${port}`
        this.logger.info(`Connecting to ${url}`)
        const socket = new WebSocket(url)
        socket.onopen = (e) => {
            this.logger.info(`Socket opened (${socket.url})`)
            const event: SocketEvent = {
                type: "open",
                event: e,
            }
            this.notify(event)
        }
        socket.onclose = (e) => {
            if (e.code == -1 && e.reason == "REPLACED") {
                this.logger.info("Socket closed (will be replaced)")
            } else {
                this.logger.info("Socket closed")
                const event: SocketEvent = {
                    type: "close",
                    event: e,
                }
                this.notify(event)
            }
        }
        socket.onerror = (e) => {
            e.stopPropagation()
            this.logger.error("Socket error")
            const event: SocketEvent = {
                type: "error",
                event: e,
            }
            this.notify(event)
        }
        socket.onmessage = (e) => {
            this.logger.trivial("Agent_message_received")
            this.logger.trivial(e.data)
            const event: SocketEvent = {
                type: "message",
                event: e,
            }
            this.notify(event)
        }

        if (this.socket) {
            this.socket.close(1000, "REPLACED")
        }

        this.socket = socket
    }

    /**
     * Connects to the specified resource.
     *
     * @return {void}
     */
    public connect() {
        this.open()
    }

    /**
     * Disconnects the socket connection.
     *
     * @return {void}
     */
    public disconnect() {
        this.socket?.close(1000, "CLIENT_CLOSE")
    }

    /**
     * Adds a SocketMessageListener to the array of listeners.
     *
     * @param {SocketEventListener} listener - The listener to be added.
     *
     * @return {void}
     */
    public addEventListener(listener: SocketEventListener) {
        if (this._socketEventListeners.indexOf(listener) == -1) {
            this._socketEventListeners.push(listener)
        }
    }

    /**
     * Removes a listener from the socket message listeners array.
     *
     * @param {SocketEventListener} listener - The listener to be removed.
     *
     * @return {SocketEventListener | null} - The removed listener, or null if the listener was not found.
     */
    public removeEventListener(
        listener: SocketEventListener
    ): SocketEventListener | null {
        const x = this._socketEventListeners.indexOf(listener)
        if (x > -1) {
            return this._socketEventListeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    /**
     * Adds a new message listener to the list of message event listeners.
     *
     * @param {MessageEventListener} listener - The message event listener to add.
     */
    public addMessageListener(listener: MessageEventListener) {
        if (this._messageEventListeners.indexOf(listener) == -1) {
            this._messageEventListeners.push(listener)
        }
    }

    /**
     * Removes a message event listener.
     *
     * @param {MessageEventListener} listener - The listener to be removed.
     * @return {MessageEventListener | null} - The removed listener, or null if the listener was not found.
     */
    public removeMessageListener(
        listener: MessageEventListener
    ): MessageEventListener | null {
        const x = this._messageEventListeners.indexOf(listener)
        if (x > -1) {
            return this._messageEventListeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    /**
     * Notifies all registered listeners with the given SocketEvent.
     *
     * @param {SocketEvent} event - The SocketEvent to notify the listeners with.
     * @private
     */
    private notify(event: SocketEvent) {
        this._socketEventListeners.forEach((listener) =>
            listener.handleSocketEvent(event)
        )
        if (event.event instanceof MessageEvent) {
            const messageEvent = event.event
            const json = JSON.parse(messageEvent.data)
            this.serviceLog.received(json.type)
            this._messageEventListeners.forEach((listener) =>
                listener.handleMessageEvent(messageEvent, json)
            )
        }
    }

    /**
     * Without anybody needing access directly to the socket, they can get its ready state.
     */
    public get readyState(): ReadyState {
        return this.socket ? this.socket.readyState : ReadyState.NoSocket
    }

    /**
     * Sends a message through the socket if the socket.
     *
     * @param {AgentMessage} message - The message to be sent.
     */
    public send(message: AgentMessage): boolean {
        if (this.socket && this.socket.readyState == this.socket.OPEN) {
            //     this.logger.info(`Sending ${message}`);
            //     this.socket.send(message.toString());
            //     return true;
            const str1: string = JSON.stringify(message)
            this.logger.info(`Sending ${str1}`)
            // tmp
            const oldstr = message.toString()
            if (str1 !== oldstr) {
                fNOP()
            }
            this.socket.send(str1)
            this.serviceLog.sent(message.type)
            return true
        } else {
            return false // trying to send to bad socket
        }
    }

    // LOCAL Vol and Pan SAVES
    private _LocalChanVPSaves = new Array<VPSaveLocal>()
    // caveat: assumes vol uses a Slider and pan uses a Knob
    public getLocalVolumeForChannel(channel: number) {
        const index = this._LocalChanVPSaves.findIndex(
            (p) => p.channel === channel
        )
        if (index >= 0) {
            return this._LocalChanVPSaves[index].vol
        } else {
            return SliderMinMax.default
        }
    }
    public setLocalVolumeForChannel(channel: number, value: number) {
        const index = this._LocalChanVPSaves.findIndex(
            (p) => p.channel === channel
        )
        if (index >= 0) {
            this._LocalChanVPSaves[index].vol = value
        } else {
            const VP: VPSaveLocal = {
                channel: channel,
                vol: value,
                pan: KnobMinMax.default,
            }
            this._LocalChanVPSaves.push(VP)
        }
    }

    public getLocalPanForChannel(channel: number) {
        const index = this._LocalChanVPSaves.findIndex(
            (p) => p.channel === channel
        )
        if (index >= 0) {
            return this._LocalChanVPSaves[index].pan
        } else {
            return KnobMinMax.default
        }
    }
    public setLocalPanForChannel(channel: number, value: number) {
        const index = this._LocalChanVPSaves.findIndex(
            (p) => p.channel === channel
        )
        if (index >= 0) {
            this._LocalChanVPSaves[index].pan = value
        } else {
            const VP: VPSaveLocal = {
                channel: channel,
                vol: SliderMinMax.default,
                pan: value,
            }
            this._LocalChanVPSaves.push(VP)
        }
    }
}

export type VPSaveLocal = {
    channel: number
    vol: number
    pan: number
}
