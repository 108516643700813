/**
 * Represents the log levels available in the application.
 * @enum {number}
 */
export enum LogLevel {
    None = 0,
    Trivial = 1,
    Log = 2,
    Info = 4,
    ToDo = 8,
    Warn = 16,
    Error = 32,

    NonTrivial = Info + ToDo + Warn + Error,
    All = Trivial + Log + Info + ToDo + Warn + Error,
}
export default class LoggerService {
    private static _appName: string = import.meta.env.VITE_APP_NAME
    private static _defaultLogLevel: LogLevel = LogLevel.NonTrivial
    private static _includeDateTime: boolean = true

    private _className: string
    private _logLevel: LogLevel

    /**
     * Creates a new instance of the LoggerService class.
     *
     * @param {string} className - The name of the class to log messages for.
     * @param {number} [logLevel=LoggerService._defaultLogLevel] - The log level to use for the class. Defaults to the default log level set in the LoggerService class.
     */
    constructor(
        className: string,
        logLevel: number = LoggerService._defaultLogLevel
    ) {
        this._className = `${LoggerService._appName}.${className}:`
        this._logLevel = logLevel
    }

    private getClassNameOptDateTime() {
        if (LoggerService._includeDateTime) {
            const dt = new Date()
            const dts: string = `${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}.${dt.getMilliseconds()}`
            return `${dts} ${this._className}`
        } else {
            return `${this._className}`
        }
    }

    public trivial(msg: any) {
        if (this._logLevel & LogLevel.Trivial) {
            console.log(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#808080;font-weight:300;",
                msg
            )
        }
    }

    public log(msg: any) {
        if (this._logLevel & LogLevel.Log) {
            console.log(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#606060;font-weight:400;",
                msg
            )
        }
    }

    public info(msg: any) {
        if (this._logLevel & LogLevel.Info) {
            console.info(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#208050;font-weight:500;",
                msg
            )
        }
    }

    public todo(msg: any) {
        if (this._logLevel & LogLevel.ToDo) {
            console.info(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#20c0c0;font-weight:600;",
                msg
            )
        }
    }

    public warn(msg: any) {
        if (this._logLevel & LogLevel.Warn) {
            console.warn(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#ff8050;font-weight:700;",
                msg
            )
        }
    }

    public error(msg: any) {
        if (this._logLevel & LogLevel.Error) {
            console.error(
                `%c${this.getClassNameOptDateTime()}`,
                "color:#ff0000;font-weight:800;",
                msg
            )
        }
    }
}
