import { useEffect, useState } from "react"
import VuBackground, {
    VuBackgroundProps,
} from "@/components/parts/VuBackground"
import ReactDOMServer from "react-dom/server"
import { Color } from "@/types/Color"
import { WebSocketService } from "@/types/WebSocket"
import LoggerService from "@/services/LoggerService"
import { LR_Type } from "./AudioControlLocal"
import { AudioInitialisationSequenceStates, fNOP } from "@/types/Device"
import { LocalDevice } from "@/services/BonzaService"
import { isIP } from "@/types/RemoteManager"

export function amplitude2dBSPLMapToMinMax(
    chanLevelF: number,
    dbclipmin: number,
    dbclipmax: number,
    metermin: number,
    metermax: number
): number {
    let tmp: number = 20.0 * Math.log10(chanLevelF)
    tmp = Math.max(dbclipmin, tmp)
    tmp = Math.min(dbclipmax, tmp)
    tmp = tmp - dbclipmin
    tmp = tmp / (dbclipmax - dbclipmin)
    tmp = tmp * (metermax - metermin) + metermin
    return tmp
}

export interface VuMeterProps extends VuBackgroundProps {
    lrtype: LR_Type
    channel?: number
    IP?: string
    portTCP?: string

    agent: WebSocketService
    value?: number
    loColor?: Color
    hiColor?: Color
    dangerColor?: Color
    dangerLevel?: number
    bgColor?: Color
    min?: number
    max?: number
    ticks?: boolean
    vals?: boolean
    columns?: number
    gapAt?: number
    invert?: boolean
    inactive?: boolean
}

export default function VuMeter(props: VuMeterProps) {
    const columns = props.columns ?? 2
    const bg = VuBackground({
        width: 10 * columns,
        fill: props.bgColor ?? undefined,
        columns: columns,
        gapAt: props.gapAt ?? undefined,
    })
    const encoded = encodeURIComponent(ReactDOMServer.renderToString(bg))

    const min = props.min ?? 0
    const max = props.max ?? 10

    const hiColor = props.hiColor ?? "#455DFF"
    const loColor = props.loColor ?? "#707070"
    const dangerColor = props.dangerColor ?? "#f57d9d"
    const dangerLevel = props.dangerLevel ?? 19 / 23

    const [level, setLevel] = useState(props.value ?? 0)
    const danger = level - dangerLevel

    const agent = props.agent
    const channel = props.channel
    const IP = props.IP
    const portTCP = props.portTCP
    if (IP != undefined && isIP(IP)) {
        fNOP()
    } else {
        fNOP()
    }
    let logger: LoggerService
    if (props.lrtype == LR_Type.Local) {
        logger = new LoggerService(`VuMeter for channel#${channel}`)
    }
    if (props.lrtype == LR_Type.Remote) {
        logger = new LoggerService(`VuMeter for IP#${IP}:${portTCP}`)
    }

    /*
     * This is for test/demo purposes only, to make the VuMeter look like it's active - take this out for real work!
     * - um *Chris* - so where does the 'real' MessageEvent handling code go, if useEffect is for test only? KB
     */
    useEffect(() => {
        if (props.lrtype == LR_Type.Local) {
            logger.log(`I'm here for channel ${channel}`)
        }
        if (props.lrtype == LR_Type.Remote) {
            logger.log(`I'm here for IP ${IP}:${portTCP}`)
        }

        const agentMessageListener = {
            handleMessageEvent(event: MessageEvent) {
                const data = JSON.parse(event.data)
                // 240327 - setLocalSoundLevelV1 is NOT IMPLEMENTED in BA @ pres...
                if (
                    data.type == "setLocalSoundLevelV1" &&
                    props.lrtype == LR_Type.Local
                ) {
                    if (data.channel == channel) {
                        const chanLevelF: number = data.level0 // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        setLevel((chanlevelDisp * 100) / max)
                    }
                } else if (
                    data.type == "setLocalSoundLevel" &&
                    props.lrtype == LR_Type.Local
                ) {
                    // channelCount : string   // num chans (eg "2")
                    // maxSampleValue : string  // `;` delimited string of floats eg "0;0.5"
                    if (channel === -1) {
                        return // this message setLocalSoundLevel does not handle MASTER (-1)
                    }
                    const numChansInMsg: number = Number(data.channelCount)
                    if (typeof numChansInMsg != typeof 1) {
                        return
                    }
                    if (channel == null || channel >= numChansInMsg) {
                        // valid channels are 0:(MAX-1)
                        // (js note: if channel === undefined then the channel == null test will pass)
                        return
                    }
                    const maxSampleValuesStr: string = data.maxSampleValue
                    let chanLevels
                    if (maxSampleValuesStr && maxSampleValuesStr.length) {
                        chanLevels = maxSampleValuesStr.split(";")
                    } else {
                        return
                    }
                    if (chanLevels.length <= numChansInMsg) {
                        return
                    }
                    if (channel >= 0) {
                        const chanLevelF: number = Number(chanLevels[channel]) // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        // dB = 20 * log10(amplitude)
                        // vu meter should display -20 to +3dB (SPL)
                        // but std range on this meter is 0:100.

                        setLevel((chanlevelDisp * 100) / max)
                    }
                } else if (
                    data.type == "setRemoteSoundLevel" &&
                    props.lrtype == LR_Type.Remote
                ) {
                    /*
                        data1, data2, data3, data4
                        ID,    value, IP,    port
                    */
                    if (data.data3 == IP && data.data4 == portTCP) {
                        const chanLevelF: number = Number(data.data2) // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        setLevel((chanlevelDisp * 100) / max)
                    } else {
                        if (IP == undefined) {
                            fNOP()
                        } else {
                            if (!isIP(IP)) {
                                fNOP()
                            }
                        }
                    }
                }
            },
        }

        agent.addMessageListener(agentMessageListener)

        const fakeMeterSignals: boolean = false // test audio in
        const updatems: number = 200
        const x = setInterval(() => {
            if (fakeMeterSignals) {
                let v = Math.random()
                v = amplitude2dBSPLMapToMinMax(v, -20, 3, min, max)
                setLevel(v * 10)
            }
        }, updatems)

        return () => {
            // clearInterval(x);
            agent.removeMessageListener(agentMessageListener)
        }
    }, [props.IP])

    const tickMarks = []
    const tickVals = []

    const ticks = props.ticks
    const vals = props.vals

    if (ticks || vals) {
        for (let i = max; i >= min; i--) {
            if (ticks) {
                tickMarks.push(
                    <p key={i} className="grow">
                        -
                    </p>
                )
            }
            if (vals) {
                tickVals.push(
                    <p key={i} className="grow">
                        {i}
                    </p>
                )
            }
        }
    }

    const ticksWidth = ticks ? 4 : 1
    const valsWidth = vals ? 4 : 1

    return (
        <div
            className={`
                ${props.className}

                flex flex-col
            `}
        >
            <div
                className={`
                    flex-1

                    w-${3.2 * columns + ticksWidth + valsWidth}

                    flex flex-row bg-bonza-dark px-2 py-7
                `}
                style={{
                    borderRadius: "8px",
                }}
            >
                <div
                    className={`
                        w-${ticksWidth}

                        -my-3 flex flex-col pr-1.5
                    `}
                    style={{ color: loColor }}
                >
                    {ticks ? tickMarks : <p className="grow">&nbsp;</p>}
                </div>
                <div
                    className={`
                        flex-1

                        w-${2.5 * columns}
                    `}
                    style={{
                        backgroundColor: `${loColor}`,
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: `${hiColor}`,
                            height: `${level}%`,
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                        }}
                    />
                    {danger > 0 ? (
                        <div
                            style={{
                                backgroundColor: `${dangerColor}`,
                                height: `${danger}%`,
                                position: "absolute",
                                bottom: `${dangerLevel}%`,
                                width: "100%",
                            }}
                        />
                    ) : (
                        ""
                    )}
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,${encoded}")`,
                        }}
                    />
                </div>
                <div
                    className={`
                        w-${valsWidth}

                        -my-4 flex flex-col items-center pl-2 pt-3 text-tiny
                    `}
                    style={{ color: loColor }}
                >
                    {vals ? tickVals : <p className="grow">&nbsp;</p>}
                </div>
            </div>
        </div>
    )
}
