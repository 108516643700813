import { ExpandMore } from "@mui/icons-material"
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    InputHTMLAttributes,
} from "react"

export default forwardRef(function SelectInput(
    {
        type = "text",
        className = "",
        isFocused = false,
        ...props
    }: InputHTMLAttributes<HTMLSelectElement> & { isFocused?: boolean },
    ref
) {
    const localRef = useRef<HTMLSelectElement>(null)

    useImperativeHandle(ref, () => ({
        focus: () => localRef.current?.focus(),
    }))

    useEffect(() => {
        if (isFocused) {
            localRef.current?.focus()
        }
    }, [])

    return (
        <div className={"relative w-full"}>
            <select
                {...props}
                className={`
                    w-full rounded-3xl border-bonza-pale bg-none p-4

                    dark:border-none dark:bg-bonza-dark dark:text-bonza-pale

                    disabled:text-bonza-grey

                    focus:border-indigo-500 focus:ring-indigo-500

                    ${className}
                `}
                ref={localRef}
            />
            <ExpandMore
                sx={{
                    position: "absolute",
                    right: "20px",
                    top: "calc(50% - 20px)",
                    height: "40px",
                    width: "40px",
                    color: "rgb(205 170 248)",
                }}
            />
        </div>
    )
})
