import { LocalDevice } from "@/services/BonzaService"
import { fNOP } from "@/types/Device"
import { PlayerViewLocal } from "@/types/PlayerView"

export type LocalAction = {
    action: "SET" | "CREATE" | "EDIT" | "IMG" | "DELETE" | "TRUNCATE"
    playerArr?: PlayerViewLocal[]
    player?: PlayerViewLocal
    newLen?: number
    imgData?: {
        index: number
        img: string
    }
}

export const localReducer = (
    players: PlayerViewLocal[],
    action: LocalAction
) => {
    switch (action.action) {
        case "SET":
            return action.playerArr ? [...action.playerArr] : []
        case "CREATE":
            if (action.player) {
                players.push(action.player)
            } else if (action.playerArr) {
                action.playerArr.forEach((p) => players.push(p))
            }
            return [...players]
        case "EDIT":
            if (action.player !== undefined) {
                const playerIndex = players.findIndex(
                    (p) => action.player && p.name === action.player.name
                )
                if (playerIndex !== -1) players[playerIndex] = action.player
            }
            return [...players]
        case "IMG":
            if (action.imgData && players[action.imgData.index]) {
                players[action.imgData.index] = {
                    ...players[action.imgData.index],
                    img: action.imgData.img,
                }
                return [...players]
            }
            return players
        case "DELETE":
            if (action.player !== undefined) {
                // const deleteIndex = players.findIndex(p => p.name === action.player?.name)
                // if (deleteIndex !== -1) {
                //     return players.toSpliced(deleteIndex, 1)
                // }
                // KB 240524
                return players.filter((p) => p.name !== action.player?.name)
            } else {
                fNOP()
            }
            return [...players]
        case "TRUNCATE":
            const newLen = action.newLen ?? 0
            if (newLen < players.length) {
                //return players.toSpliced(newLen);
                // KB 240524
                return players.filter((p, i) => i < newLen)
            }
    }
    return players
}
