import axios, { AxiosResponse } from "axios"
import LoggerService from "../LoggerService"

const getRequest = async <T>(route: string, loggerService?: string) => {
    const logger = new LoggerService(loggerService ?? "Axios")

    const res = await axios
        .get(route)
        .then((response: AxiosResponse<T>) => {
            return response.data
        })
        .catch((error: Error) => {
            logger.warn(`${route} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

const postRequest = async <TData, TResponse>(
    route: string,
    data?: TData,
    loggerService?: string
) => {
    const logger = new LoggerService(loggerService ?? "Axios")

    const res = await axios
        .post(route, data)
        .then((response: AxiosResponse<TResponse>) => {
            return response.data
        })
        .catch((error: Error) => {
            logger.warn(`${route} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

const putRequest = async <TData, TResponse>(
    route: string,
    data: TData,
    loggerService?: string
) => {
    const logger = new LoggerService(loggerService ?? "Axios")

    const res = await axios
        .put(route, data)
        .then((response: AxiosResponse<TResponse>) => {
            return response.data
        })
        .catch((error: Error) => {
            logger.warn(`${route} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

const deleteRequest = async <T>(route: string, loggerService?: string) => {
    const logger = new LoggerService(loggerService ?? "Axios")

    const res = await axios
        .delete(route)
        .then((response: AxiosResponse<T>) => {
            return response.data
        })
        .catch((error: Error) => {
            logger.warn(`${route} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

export { getRequest, postRequest, putRequest, deleteRequest }
