import { MasterChannel, MASTERCHANNELNUMBER } from "@/components/MasterMixer"
import { LocalAction, localReducer } from "@/reducers/localReducer"
import { PanZoneAction, panZoneReducer } from "@/reducers/panZoneReducer"
import { PlayerAction, playerReducer } from "@/reducers/playerReducer"
import {
    Agent,
    Bonza,
    BonzaService,
    LocalDevice,
    LocalRemoteManager,
} from "@/services/BonzaService"
import { useSessionListener } from "@/services/helpers/SessionHelper"
import LoggerService, { LogLevel } from "@/services/LoggerService"
import { NwkInfo } from "@/services/NwkInfoService"
import { Video } from "@/services/VideoService"
import {
    inputChannelCountPossibilities,
    LoopbackIP,
    LoopbackPort,
    setReceiverBufferSizeMessage,
    setScreenSharingMessage,
} from "@/types/AppMessage"
import { DeviceChangeEventType, IDevice } from "@/types/Device"
import { PanZoneModel } from "@/types/PanZone"
import {
    PlayerViewLocal,
    PlayerViewRemote,
    RTInfo,
    RTInfoEmptyField,
} from "@/types/PlayerView"
import {
    IRemoteManager,
    isIP,
    RemoteChangeEventType,
    RemoteConnectState,
    RemoteInfo,
    RISSFlags,
} from "@/types/RemoteManager"
import { User } from "@/types/UserClass"
import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
} from "@/types/WebSocket"
import { enqueueSnackbar, SnackbarProvider } from "notistack"
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react"

type BonzaContextProps = {
    user: User | undefined
    setUser: Dispatch<User | undefined>
    players: PlayerViewRemote[]
    setPlayers: Dispatch<PlayerAction>
    panZones: PanZoneModel[]
    setPanZones: Dispatch<PanZoneAction>
    locals: PlayerViewLocal[]
    setLocals: Dispatch<LocalAction>
    setInputChannelCount: (count: number) => void
    hideLocals: boolean
    screenSharing: boolean
    toggleScreenSharing: () => void
    connected: boolean
}

const BonzaContext = createContext<BonzaContextProps | undefined>(undefined)

export const BonzaContextProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<User | undefined>(undefined)

    const [players, setPlayers] = useReducer(
        playerReducer,
        new Array<PlayerViewRemote>()
    )

    // TEMP placeholder until zoning is re-worked - EK 11/09/24
    const [panZones, setPanZones] = useReducer(panZoneReducer, [
        {
            name: "Left",
            id: 0,
            players: [],
        },
        {
            name: "Center",
            id: 1,
            players: players,
        },
        {
            name: "Right",
            id: 2,
            players: [],
        },
    ])

    const connected = useMemo(
        () => Agent.readyState == ReadyState.Open,
        [Agent.readyState]
    )

    const logger = new LoggerService("Stage", LogLevel.NonTrivial)

    const [locals, setLocals] = useReducer(localReducer, [MasterChannel])

    const [hideLocals, setHideLocals] = useState(
        !LocalDevice.activeInputSoundCard
    )

    const setInputChannelCount = (count: number) => {
        const currentCount = locals.length - 1

        if (count < currentCount) {
            setLocals({
                action: "TRUNCATE",
                newLen: count + 1,
            })
        } else if (count > currentCount) {
            const newLocals = []
            for (let i = currentCount; i < count; i++) {
                newLocals.push({
                    name: `Channel ${i + 1}`,
                    agent: Agent,
                    channel: i,
                })
            }
            setLocals({
                action: "CREATE",
                playerArr: newLocals,
            })
        }
    }

    const [screenSharing, setScreenSharing] = useState(false)

    const toggleScreenSharing = () => {
        if (!screenSharing) {
            Agent.send(new setScreenSharingMessage(true))
            setScreenSharing(true)
        } else {
            Agent.send(new setScreenSharingMessage(false))
            setScreenSharing(false)
        }
    }

    const agentMessageListener: MessageEventListener = {
        handleMessageEvent(_: MessageEvent, message: any | null | undefined) {
            if (!message || !message.type) return

            switch (message.type) {
                case "sendVideoImage": {
                    if (Video.shouldUpdate("__SELF"))
                        Video.set(
                            "__SELF",
                            `data:image/jpeg;base64,${message.jpgBuffer}`
                        )
                    break
                }
                case "sendRemoteVideoImage":
                case "sendRemoteSoundLevel": {
                    /* also trap setRemoteSoundLevel here in case arrays out of step
                      TODO - if works refactor this out to sep function
                    */
                    let isVideo: boolean = false
                    let IP: string
                    let port: string
                    if (message.type == "sendRemoteVideoImage") {
                        // 240205+ new BonzaApp message for remote video:
                        IP = message.socketIP
                        port = message.portTCP
                        isVideo = true
                    } else {
                        //data1, data2, data3, data4
                        //ID,    value, IP,    port
                        IP = message.data3
                        port = message.data4
                    }

                    const ri: RemoteInfo | null =
                        LocalRemoteManager.findRemoteFromPortSkt(IP, port)
                    const pindex = players.findIndex(
                        (p) => p.ip === IP && p.port === port
                    )
                    if (ri) {
                        // found remote in LM's array
                        // now check display array for similar match ...
                        if (pindex > -1) {
                            // found it, so OK...
                            if (isVideo && Video.shouldUpdate(ri.remoteName)) {
                                Video.set(
                                    ri.remoteName,
                                    `data:image/jpeg;base64,${message.jpgBuffer}`
                                )
                            }
                        } else {
                            const player = {
                                name: ri.remoteName,
                                img: "", // no need for img yet - will do in next step...
                                agent: Agent,
                                ip: IP,
                                port: port,
                                readiness: RemoteConnectState.verified, // verified because we are getting this message!
                            }
                            players.push(player)
                            // but now do an async update for GUI reasons... set the IMG
                            if (isVideo && Video.shouldUpdate(ri.remoteName)) {
                                Video.set(
                                    ri.remoteName,
                                    `data:image/jpeg;base64,${message.jpgBuffer}`
                                )
                            }
                        }
                    } else {
                        // no ri
                        // special cases since LH and Mirror do not (currently) get added to the array of remotes...
                        if (IP == LoopbackIP && port == LoopbackPort) {
                            return
                        }
                        if (isVideo) {
                            logger.warn(
                                `Stage:sendRemoteVideoImage - remote not found in remotes array ${IP}:${port}`
                            )
                        } else {
                            logger.warn(
                                `Stage:setRemoteSoundLevel - remote not found in remotes array ${IP}:${port}`
                            )
                        }
                        if (pindex > -1) {
                            const player = players[pindex]
                            setPlayers({
                                action: "DELETE",
                                player: player,
                            })
                        }
                    }

                    break
                }

                // just handle self & any remotes' VIDEO here
                // - other messages eg setRemoteSoundLevel etc handled elsewhere
                case "tellLatency":
                    {
                        // Agent sending curr latency for display
                        const ip = message.data3
                        const port = message.data4
                        const info: RTInfo = {
                            latency: message.data2,
                            dropout: RTInfoEmptyField,
                        }
                        const player = players.find(
                            (p) => p.ip === ip && p.port === port
                        )
                        if (player) {
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                case "tellDropout":
                    {
                        // Agent sending curr dropout for display
                        const ip = message.data3
                        const port = message.data4
                        const info: RTInfo = {
                            dropout: `${message.data2}`,
                        }
                        const logDropout: boolean = false
                        if (logDropout) {
                            logger.info(
                                `Stage:tellDropout ${ip}:${port} ${info.dropout}`
                            )
                        }
                        const player = players.find(
                            (p) => p.ip === ip && p.port === port
                        )
                        if (player) {
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                case "setJitterBuffer": {
                    // Agent sending curr jitter suggestion - we dont have to do it if already in 'manual' mode
                    const ip = message.IP
                    const port = message.portTCP
                    const info: RTInfo = {
                        jitter: message.newJBValue,
                        dropout: RTInfoEmptyField,
                    }
                    const player = players.find(
                        (p) => p.ip === ip && p.port === port
                    )
                    if (player) {
                        if (LocalDevice.isAutoJB()) {
                            NwkInfo.set(player.name, info)
                            Agent.send(
                                new setReceiverBufferSizeMessage(
                                    message.IP,
                                    message.portTCP,
                                    message.newJBValue
                                )
                            )
                        } else {
                            // manual jb - just use the (global) manual one of this remote
                            info.jitter =
                                LocalDevice.deviceDataStore.advancedSettings.manualJitterIndex
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                }
                default: {
                    logger.log("No valid message")
                    break
                }
            }
        },
    }

    const stageRemoteChangeListener = {
        handleRemoteChange(
            _: IRemoteManager,
            eventType: RemoteChangeEventType,
            remoteinfo: RemoteInfo
        ) {
            const name: string = remoteinfo.remoteName
            //
            // both below can be empty IF we havent got relevant info yet from server...
            //var IP: string = remoteinfo.engineIP; // pre 240711
            let IP: string = remoteinfo.remoteTCPAddr
            const port: string = remoteinfo.remoteTCPPort
            const readiness: RemoteConnectState = remoteinfo.remoteConnectState
            if (IP.length == 0 || port.length == 0) {
                // not had update with IP and port yet..
                IP = name // substitute name for now till update arrives...
            }

            switch (eventType) {
                case RemoteChangeEventType.Add: {
                    const IPstr: string = isIP(IP) ? ` ${IP}` : ``
                    logger.info(`RCET Adding ${name}${IPstr}`)
                    const player = {
                        name: name,
                        //img: "",
                        agent: Agent,
                        ip: IP,
                        port: port,
                        readiness: readiness,
                    }
                    setPlayers({
                        action: "CREATE",
                        player: player,
                    })
                    setPanZones({
                        player: player,
                        panZone: panZones[1],
                    })
                    enqueueSnackbar(`You're now connected with ${name}.`, {
                        variant: "success",
                    })
                    break
                }
                case RemoteChangeEventType.Remove: {
                    const playerlenpre: number = players.length
                    logger.info(
                        `RCET attempt remove ${name} (${IP}) from players (len ${playerlenpre})`
                    )
                    const player = players.find((p) => p.name == name)

                    const assumePlayersLenNotValid = true // 240605KB

                    if (player || assumePlayersLenNotValid) {
                        if (player) {
                            setPanZones({
                                player: player,
                                delete: true,
                            })
                        }
                        // if assumePlayersLenNotValid & !player
                        // send delete anyway...
                        setPlayers({
                            action: "DELETE",
                            player: {
                                name: name,
                                img: "",
                                agent: Agent,
                                ip: IP,
                                port: port,
                                readiness: readiness,
                            },
                        })
                        enqueueSnackbar(`You're disconnected from ${name}.`, {
                            variant: "warning",
                        })
                    } else {
                        enqueueSnackbar(
                            `attempt disconnect from ${name} - wasnt connected, ignoring`,
                            {
                                variant: "info",
                            }
                        )
                        // poss out of step here KB 240705
                    }
                    break
                }
                case RemoteChangeEventType.Readyness:
                    logger.info(`RCET Readiness ${IP} = ${readiness}`)
                    setPlayers({
                        action: "EDIT",
                        player: {
                            name: name,
                            img: "",
                            agent: Agent,
                            ip: IP,
                            port: port,
                            readiness: readiness,
                        },
                    })
                    break
                case RemoteChangeEventType.RemoveAll: {
                    const playerlen: number = players.length
                    logger.info(
                        `RCET Remove All from players (len ${playerlen})`
                    )
                    setPlayers({
                        action: "SET",
                        playerArr: undefined, // 240327 this should zap the players array
                    })
                    break
                }
                default:
                    logger.error(`Unknown RemoteChangeEventType ${eventType}`)
                    break
            }
        },
    }

    const stageDeviceChangeListener = {
        handleDeviceChange(device: IDevice, eventType: DeviceChangeEventType) {
            switch (eventType) {
                case DeviceChangeEventType.ActiveSoundCard: {
                    const sc = device.activeInputSoundCard
                    if (!sc) {
                        setHideLocals(true)
                        return
                    }
                    if (hideLocals) setHideLocals(false)
                    const scInsCount = sc.inputChannels
                    if (!scInsCount || scInsCount == 0) {
                        return
                    }
                    const inUseCount =
                        inputChannelCountPossibilities[
                        Number(
                            LocalDevice.getSavedSettings().inChannelsIndex
                        )
                        ]
                    if (!inUseCount || inUseCount == 0) {
                        return
                    }
                }
            }
        },
    }

    useEffect(() => {
        if (user !== undefined) {
            if (BonzaService.user === null)
                BonzaService.user = user

            const bonzaEventListener: SocketEventListener = {
                handleSocketEvent(event: SocketEvent) {
                    logger.trivial(event)
                    if (event.type == "open") {
                        LocalRemoteManager.setOpen()
                        if (user.name != BonzaService.user?.name) {
                            logger.warn(
                                `auth.user:${user.name} != BonzaService.user:${BonzaService.user?.name}`
                            )
                        }
                        LocalRemoteManager.login(user)
                    }
                },
            } //TODO - temporary fake locations, **set fakeLocationCount between 1 and 8 to test**
            const fakeLocationCount = 4
            const imgs = Object.values(
                import.meta.glob("@img/player*.{png,jpg}", {
                    eager: true,
                    as: "url",
                })
            )

            const index = panZones.length > 1 ? 1 : 0
            const panZone = panZones[index]
            if (!panZone.players) {
                panZone.players = []

                for (let i = 1; i <= fakeLocationCount; i++) {
                    panZone.players.push({
                        name: i ? `Location ${i + 1}` : `Me`,
                        img: imgs[i],
                        agent: Agent,
                        ip: `127.0.0.${i + 1}`,
                        port: undefined,
                    })
                    logger.log(`Location ${i + 1}: ${imgs[i]}`)
                }
                setPlayers({
                    action: "SET",
                    playerArr: panZone.players,
                })
            }

            if (!LocalDevice.fakeLocals) {
                LocalDevice.fakeLocals = [
                    {
                        name: `Master`,
                        agent: Agent,
                        channel: MASTERCHANNELNUMBER,
                    },
                ]
                setLocals({
                    action: "SET",
                    playerArr: LocalDevice.fakeLocals,
                })
            }

            const count =
                inputChannelCountPossibilities[
                Number(LocalDevice.getSavedSettings().inChannelsIndex)
                ]
            setInputChannelCount(count)

            if (Bonza.readyState !== ReadyState.Open) {
                Bonza.join()
                Bonza.addEventListener(bonzaEventListener)
                Bonza.open()
            }

            if (Agent.readyState !== ReadyState.Open) {
                Agent.addMessageListener(agentMessageListener)
                Agent.connect()
            }

            if (!(LocalRemoteManager.initState & RISSFlags.RISS_LoggedIn))
                LocalRemoteManager.addRemoteChangeListener(
                    stageRemoteChangeListener
                )

            if (!(LocalDevice.initState & RISSFlags.RISS_Ready))
                LocalDevice.addDeviceChangeListener(stageDeviceChangeListener)
        }
    }, [user])

    useSessionListener()

    return (
        <BonzaContext.Provider
            value={{
                players,
                setPlayers,
                panZones,
                setPanZones,
                user,
                setUser,
                locals,
                setLocals,
                setInputChannelCount,
                hideLocals,
                screenSharing,
                toggleScreenSharing,
                connected,
            }}
        >
            <SnackbarProvider maxSnack={5} disableWindowBlurListener={true}>
                {children}
            </SnackbarProvider>
        </BonzaContext.Provider>
    )
}

export function useBonzaContext(): BonzaContextProps {
    const ctx = useContext(BonzaContext)
    if (ctx === undefined) throw Error("Context is undefined")
    return ctx
}

export default BonzaContext
