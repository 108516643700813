import { useEffect } from "react"
import { ConnectionResource } from "@/types/Connection"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { BonzaSessionResponse } from "@/types/BonzaSession"
import { getUserSessions } from "@/services/UserService"

type BonzaSessionUpdatedProps = Partial<BonzaSessionResponse> & {
    joined?: ConnectionResource
    left?: ConnectionResource
    activated_at?: string
    deactivated_at?: string
}

export function useSessionListener() {
    const queryClient = useQueryClient()

    const { data: dataSessions } = useQuery<BonzaSessionResponse[]>({
        queryKey: ["sessions"],
        queryFn: getUserSessions,
    })

    const sessionUpdateCallback = (data: BonzaSessionUpdatedProps) => {
        if (data.activated_at) {
            console.log("Session started", new Date(data.activated_at))
        } else if (data.deactivated_at) {
            console.log("Session started", new Date(data.deactivated_at))
        } else if (data.joined) {
            console.log("User joined", data.joined.user_id)
            queryClient.invalidateQueries({ queryKey: ["sessionConnections"] })
        } else if (data.left) {
            console.log("User left", data.left.user_id)
            queryClient.invalidateQueries({ queryKey: ["sessionConnections"] })
        } else {
            console.log("Session update", data)
        }
    }

    useEffect(() => {
        dataSessions?.forEach((session) => {
            window.Echo.private(`Bonza.Session.${session.id}`)
                .error((error: unknown) => console.error(error))
                .subscribed(() => console.log(`Subscribed to ${session.id}`))
                .listen("BonzaSessionUpdated", sessionUpdateCallback)
        })

        return dataSessions?.forEach((session) =>
            window.Echo.leaveChannel(`Bonza.Session.${session.id}`)
        )
    }, [dataSessions?.length])
}
