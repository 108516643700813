import {
    LocalDevice,
    Agent,
    LocalRemoteManager,
    Bonza,
    BonzaService,
} from "@/services/BonzaService"
import LoggerService, { LogLevel } from "@/services/LoggerService"
import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
} from "@/types/WebSocket"
import {
    ProbeMessage,
    getSettingsV240124Message,
    inputDeviceSelectMessageV240124,
    outputDeviceSelectMessageV240124,
    standaloneMessage,
    setInterfaceMessage,
    getExternalIPAndPortMessage,
    settingsDataV240124,
    StartAudioMessageWithMostParams,
    saveSettingsToFileV240124Message,
    latencies,
    inputChannelCountPossibilityIndices,
    inputChannelCountPossibilities,
    setVideoDeviceMessage,
    getUUIDMessage,
    setColorOrBWMessage,
    setVideoResolutionMessage,
    spatialLocationMessage,
    spatialLocations,
    diffuseIRDepthMessageV240124,
    setOzoneMessage,
    ozoneMessages,
    localDirectOnOffMessageV240124,
    advancedSettingsDevZZZ,
    SampleRateValueStrings,
    SampleRateValues,
    NetworkBufferSizes,
    setStreamQualityMessage,
    defaultNICIndex,
} from "@/types/AppMessage"
import { PlayerViewLocal } from "./PlayerView"
import { User } from "./UserClass"
import { AgentService } from "@/services/AgentService"
import { RISSFlags } from "./RemoteManager"

export type SoundCard = {
    index: number
    name: string
    inputChannels: number
    outputChannels: number
}

export type VideoDevice = {
    index: number
    name: string
}

export enum DeviceChangeEventType {
    SoundCards,
    ActiveSoundCard,
    VideoDevices,
    ActiveVideoDevice,
}

export enum AudioInitialisationSequenceStates {
    AISS_Uninitialised,
    AISS_1, // up to sent "probe"
    AISS_2,
    AISS_3,
    AISS_Ready,
    AISS_Confirmed,
    AISS_Error,
}

export interface DeviceChangeListener {
    handleDeviceChange(device: IDevice, eventType: DeviceChangeEventType): void
}

export interface IDevice {
    soundCards: Array<SoundCard>
    inputSoundCards: Array<SoundCard>
    outputSoundCards: Array<SoundCard>
    activeInputSoundCard: SoundCard | null
    activeOutputSoundCard: SoundCard | null
    videoDevices: Array<VideoDevice>
    activeVideoDevice: VideoDevice | null

    addDeviceChangeListener(listener: DeviceChangeListener): void
    removeDeviceChangeListener(
        listener: DeviceChangeListener
    ): DeviceChangeListener | null
    getSavedSettings(): settingsDataV240124
    saveSavedSettings(newSettings: settingsDataV240124): void
    sequence_Start(inhibRequestNewSettings: boolean): void
    deviceDataStore: deviceDataStore
}

class deviceDataStore {
    private _savedSettings: settingsDataV240124
    private aNICs: Array<string> = []
    private _currentBAVersionN: number = 0
    //private _expectedBAVersionN: number = 240124;
    private _expectedBAVersionN: number = 240501
    public UUID: string = ""

    public advancedSettings: advancedSettingsDevZZZ =
        new advancedSettingsDevZZZ()

    // for now just public later make getters & setters
    // these only sync with the gui when gui changed - so make sure init vals are also in sync!
    //public inChans: number = 0;
    //public inChansSelectIndex: number = 0;
    // !!! embed manual dep on outChans wrt GUI default
    //public outChans: number = 2;
    //public outChansSelectIndex: number = 1; // default 1 ie 2 chans, also done in BasicSelect.
    //public currLoggedInAs: string = ""; // so can send user??? TBC
    //public TPInfo: any;
    /*public userZonesTMP: { user: UserModel | null, zone: ZoneModel | null } = {
        user: null,
        zone: null
    };*/
    //    user: import("/Users/kennethbrown/Desktop/Local/Bonza/CodeMain/bonza-webapp/resources/js/types/User").UserModel; zone: import("/Users/kennethbrown/Desktop/Local/Bonza/CodeMain/bonza-webapp/resources/js/types/Zone").ZoneModel; };

    constructor() {
        this.aNICs = []
        this._savedSettings = new settingsDataV240124()
    }

    public saveNICData(ifs: Array<string>) {
        this.aNICs = ifs.filter((p) => !p.startsWith("127.0.0.")) // !!! temp fix wrt "hack" in BA: 240624VideoSpeedDebug sj.cpp 2166
        fNOP()
    }

    public getaNICs() {
        return this.aNICs
    }

    public saveSavedSettings(settings: settingsDataV240124 | null) {
        if (settings == null) {
            return
        }
        if (
            settings.audioInCardName === undefined ||
            settings.audioOutCardName === undefined
        ) {
            fNOP() // probably invalid
        } else {
            fNOP() // potentially valid
        }
        this._savedSettings = { ...settings }
    }

    public getSavedSettings(): settingsDataV240124 {
        return this._savedSettings
    }

    public get currentBAVersionN(): number {
        return this._currentBAVersionN
    }

    public set currentBAVersionN(cbv: number) {
        this._currentBAVersionN = cbv
    }

    public get expectedBAVersionN(): number {
        return this._expectedBAVersionN
    }
} // eo class workingDataStore

export function fNOP() {
    // do nothing but allow breakpoint
}

export class Device implements IDevice {
    private _deviceChangeListeners: Array<DeviceChangeListener> = []
    public logger: LoggerService = new LoggerService("LocalDevice")

    private _initialisationState: AudioInitialisationSequenceStates
    private _deviceData: deviceDataStore = new deviceDataStore()

    // should these go in workingData so stuff all in same place???
    private _soundCards: Array<SoundCard> = []
    private _activeInputSoundCard: SoundCard | null = null
    private _activeOutputSoundCard: SoundCard | null = null
    private _videoDevices: Array<VideoDevice> = []
    private _activeVideoDevice: VideoDevice | null = null

    private idt1: any = 0 // ReturnType<typeof setTimeout> ??? what is it?
    private idt2: any = 0
    private crashIndicatedIfGetsTooHighCounter: number = 0

    public fakeLocals: Array<PlayerViewLocal> | null = null

    constructor() {
        this._initialisationState =
            AudioInitialisationSequenceStates.AISS_Uninitialised

        Agent.addEventListener(this.agentEventListener)
        Agent.addMessageListener(this.agentMessageListener)

        this._deviceData = new deviceDataStore()
        this.idt1 = 0
        this.idt2 = 0
        this.crashIndicatedIfGetsTooHighCounter = 0

        this.logger.log(
            `Device Constructor, agent state is ${ReadyState[Agent.readyState]}`
        )
        if (Agent.readyState == ReadyState.Open) {
            this.logger.info(
                `Device Constructor : Agent ReadyState was Open : starting initialisation sequence now...`
            )
            this.sequence_Start(false)
        } else {
            // Agent not ready so no point attempting to send things...
            // so sequence gets started after a timeout when agentEventListener detects socket open
        }
    }

    public isAutoJB(): boolean {
        return this._deviceData.advancedSettings.manualJitterIndex === "0"
    }
    // *true* means incoming Agent setJitterBuffer msgs get sent
    // back as setReceiverBufferSizeMessage; false implies WE have a manual gui to send
    // setReceiverBufferSizeMessages whenever the GUI is changed

    public get initState(): AudioInitialisationSequenceStates {
        return this._initialisationState
    }

    public set initState(newstate: AudioInitialisationSequenceStates) {
        this._initialisationState = newstate
    }

    public get deviceDataStore(): deviceDataStore {
        return this._deviceData
    }

    public set deviceDataStore(store: deviceDataStore) {
        this._deviceData = store
    }

    public getSavedSettings(): settingsDataV240124 {
        return this._deviceData.getSavedSettings()
    }

    public saveSavedSettings(newSettings: settingsDataV240124) {
        return this._deviceData.saveSavedSettings(newSettings)
    }

    public get soundCards(): Array<SoundCard> {
        return this._soundCards
    }

    public get inputSoundCards(): Array<SoundCard> {
        return this._soundCards.filter((card) => card.inputChannels > 0)
    }

    public get outputSoundCards(): Array<SoundCard> {
        return this._soundCards.filter((card) => card.outputChannels > 0)
    }

    public set activeInputSoundCard(soundCard: SoundCard | null) {
        this._activeInputSoundCard = soundCard
        if (soundCard != null) {
            Agent.send(new inputDeviceSelectMessageV240124(soundCard.index))
        } else {
            this.getSavedSettings().audioInCardName = undefined
        }
        this.logger.info(
            `Active input sound card was set to ${soundCard ? soundCard.name : "null"}`
        )
        this.notify(DeviceChangeEventType.ActiveSoundCard)
    }

    public get activeInputSoundCard(): SoundCard | null {
        return this._activeInputSoundCard
    }

    public set activeOutputSoundCard(soundCard: SoundCard | null) {
        this._activeOutputSoundCard = soundCard
        if (soundCard != null) {
            Agent.send(new outputDeviceSelectMessageV240124(soundCard.index))
        } else {
            this.getSavedSettings().audioOutCardName = undefined
        }
        this.logger.info(
            `Active output sound card was set to ${soundCard ? soundCard.name : "null"}`
        )
        this.notify(DeviceChangeEventType.ActiveSoundCard)
    }

    public get activeOutputSoundCard(): SoundCard | null {
        return this._activeOutputSoundCard
    }

    // todo : videoCard
    public get videoDevices(): Array<VideoDevice> {
        return this._videoDevices
    }

    public get activeVideoDevice(): VideoDevice | null {
        return this._activeVideoDevice
    }

    public set activeVideoDevice(videoDevice: VideoDevice | null) {
        this._activeVideoDevice = videoDevice
        if (videoDevice != null) {
            const settings = this.getSavedSettings()
            Agent.send(
                new setVideoResolutionMessage(
                    Number(settings.videoResolutionIndex)
                )
            )
            Agent.send(
                new setColorOrBWMessage(Number(settings.videoColorIndex))
            )
            Agent.send(new setVideoDeviceMessage(videoDevice.index.toString()))
        } else {
            // stopVideo - send index=="0"
            Agent.send(new setVideoDeviceMessage("0"))

            /// TEST - works so can get rid
            //Agent.send(new setVideoResolutionMessage(1));
            //Agent.send(new setColorOrBWMessage(1));
        }
        this.logger.log(
            `Active video device was set to ${videoDevice ? videoDevice.name : "null"}`
        )
        this.notify(DeviceChangeEventType.ActiveVideoDevice)
    }

    public stopTimers() {
        if (this.idt1 != 0) {
            clearTimeout(this.idt1)
            this.idt1 = 0
        }
        if (this.idt2 != 0) {
            clearInterval(this.idt2)
            this.idt2 = 0
        }
    }

    // !!! rough & ready - just to get (eventually) SJ: SOUND IS RUNNING from a clean start purely for test VUMeters
    public sequence_Start(inhibRequestNewSettings: boolean): boolean {
        this.stopTimers() // pre-stop JIC already running
        this.logger.info(`sequence_Start`)
        this._initialisationState =
            AudioInitialisationSequenceStates.AISS_Uninitialised
        let rissState = LocalRemoteManager.initState
        if (rissState & RISSFlags.RISS_AudioRunning) {
            const DEBnRemotes = LocalRemoteManager.workingData.remotes.length
            rissState &= ~RISSFlags.RISS_AudioRunning
            rissState &= ~RISSFlags.RISS_Ready
            LocalRemoteManager.initState = rissState
        }

        this._soundCards = []
        this._videoDevices = []
        if (BonzaService.user) {
            const settings: settingsDataV240124 =
                this.deviceDataStore.getSavedSettings()
            settings.userName = BonzaService.user.name
            this.deviceDataStore.saveSavedSettings(settings)
        } else {
            return false
        }
        if (!inhibRequestNewSettings) {
            Agent.send(new getUUIDMessage())
            Agent.send(new getSettingsV240124Message()) // hmm may NOT want to load settings IFF we've just changed them from here???
            const codecOptionsIndex =
                LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex.toString()
            Agent.send(new setStreamQualityMessage(codecOptionsIndex)) // 240912 added KB jic engine not set up properly
        } else {
            fNOP() // request Settings from Agent inibited...
        }
        Agent.send(new standaloneMessage("public", BonzaService.user.name))
        Agent.send(new ProbeMessage())
        this.logger.log(`Probing sound cards...`)
        this._initialisationState = AudioInitialisationSequenceStates.AISS_1
        this.logger.log(`ISS_1...`)
        this.crashIndicatedIfGetsTooHighCounter = 0
        this.idt1 = setTimeout(this.timedEventFunction.bind(this), 1000)
        this.idt2 = setInterval(this.timedEventFunction.bind(this), 5000)
        return true
    }

    // !!! rough & ready - just to get (eventually) SJ: SOUND IS RUNNING from a clean start purely for test VUMeters
    public timedEventFunction() {
        // in old gui this was "goForExternalIPAndPort"
        if (this._deviceData == undefined) {
            this.logger.error("Device:250 workingData is undefined")
            return
        }

        if (
            this._initialisationState ==
            AudioInitialisationSequenceStates.AISS_Error
        ) {
            this.stopTimers()
            return
        }

        this.crashIndicatedIfGetsTooHighCounter++
        const counterThresholdForCrashDetection: number = 4

        let ALLOW_DEBUGGING = true // !!! manually set to true IFF will be breakpointing
        // bonza.app - to stop the GUI going into AISS_Error mode!

        // but dont allow an accidentally set true value to percolate into a production build!
        if (ALLOW_DEBUGGING && import.meta.env.VITE_APP_ENV === "production") {
            ALLOW_DEBUGGING = false
        }

        if (
            !ALLOW_DEBUGGING &&
            this.crashIndicatedIfGetsTooHighCounter >=
                counterThresholdForCrashDetection
        ) {
            if (Agent.readyState == ReadyState.Open) {
                alert(
                    `The Bonza Audio Engine is unresponsive - please stop and restart it.`
                )
                Agent.disconnect()
                Agent.connect() // seem to need this to kick power button off if Bonza (Agent)
                // still not connectable
            } else {
                this.logger.warn(
                    `Counter got too high Agent not connected - please reconnect`
                )
                fNOP() // not even connected - user should toggle the power button to fix
            }
            this._initialisationState =
                AudioInitialisationSequenceStates.AISS_Error
            // once in AISS_Error state - user needs to toggle the power button to fix
            this.crashIndicatedIfGetsTooHighCounter = 0
            return
        } else if (
            ALLOW_DEBUGGING &&
            this.crashIndicatedIfGetsTooHighCounter ==
                counterThresholdForCrashDetection
        ) {
            this.logger.warn(
                `Counter got too high while ALLOW_DEBUGGING - but ignore...`
            )
        }

        if (
            this._initialisationState ==
            AudioInitialisationSequenceStates.AISS_2
        ) {
            // AISS_1 -> AISS_2 happens when get a setNICOptions message
            // which implies all probe messages have already arrived.
            // so if we were waiting for them (ISS2) now we can progress to ISS3

            // TO SUSS w ALEX !!! - this represents nics - also (VPN) which presumably is for AC's GUI
            // the trouble is - lets say my Mac has wired and wifi - poss need a new DemoGUI selector to choose
            // Also - a problem discovered UoY 240716 is that Bonza (engine) sometimes doesnt detect ANY interfaces.
            // so the below test failed - resulting in audio didnt start (now refactored so this doesnt happen)

            // 240912 - see LocalDeviceDialog - if PC and Mac both have both wifi AND nwk - and since lists are opposite order
            // between OPC and Mac - just selecting [0] results in a ONE-WAY error if on LAN & connect from PC to Mac
            // mac was 381 but PC was 181
            // 240912 - added ability to choose in advanced settings - but probably need to somehow auto-set these???
            // needs further testing???

            let nicIndex: number = Number(
                this.deviceDataStore.advancedSettings.nicIndex ?? "-1"
            )
            const IFs = this._deviceData.getaNICs()
            const nNICs = IFs.length
            if (nicIndex < 0 || isNaN(nicIndex)) {
                nicIndex = defaultNICIndex
            }
            if (nicIndex >= nNICs) {
                nicIndex = defaultNICIndex
            }
            if (IFs && IFs[nicIndex] && IFs[nicIndex].length) {
                //Agent.send(new setInterfaceMessage(IFs[0]))
                // ???? how does it know which IP address to use???
                // default use first slot [0] but see above comment @ 240912
                Agent.send(new setInterfaceMessage(IFs[nicIndex])) // an IP address
            }

            //     // BUT this all is triggered originally from a login - stage may not even be selected - so
            //     // poss useEffects for stage contents wont have run yet, so handlers wont be subscribed...
            //     // so need to defer any auto-select soundcard until even later...

            // } /* else if (TPInfo && TPInfo.InterfaceIP && TPInfo.localIP) {
            //     // ???
            //     Agent.send(new setInterfaceMessage(TPInfo.InterfaceIP));
            // } */
            this._initialisationState = AudioInitialisationSequenceStates.AISS_3 // 240731 just go from AISS_2->3 regardless!
        }

        //Agent.send(new getExternalIPAndPortMessage()); // we do this at timed intervals
        // 240820 fix Bonza crash if GUI already running but connect to Bonza late
        if (
            this._initialisationState >=
            AudioInitialisationSequenceStates.AISS_3
        ) {
            Agent.send(new getExternalIPAndPortMessage()) // we do this at timed intervals
            // this causes a "TellPort" message back from Agent...
        }

        if (
            this._initialisationState ==
            AudioInitialisationSequenceStates.AISS_3
        ) {
            // iss2->3 is when we know BA is ready,
            // as long as we know which soundcards to use...
            // & all i/o chans have been set - at pres after a reload various things
            // eg output chans is NOT set???

            // if we have no soundcard list cant progress...
            const nscs = this.soundCards.length
            if (nscs == 0) {
                if (this.activeInputSoundCard) this.activeInputSoundCard = null
                if (this.activeOutputSoundCard)
                    this.activeOutputSoundCard = null
                return
            }

            // because Device's audio initialisation sequencing starts up before Stage,
            // need to wait until we know the Stage GUI is ready to accept any auto-changes...
            // at pres can do this by examining num listeners, but whole thing needs improving/redisigning...
            const probablyNotGotHandlers: boolean =
                this._deviceChangeListeners.length < 2
            if (probablyNotGotHandlers) {
                return
            }

            const workingDataRef = this.getSavedSettings()
            let toNotify = false
            let settingsNeedSaving: boolean = false

            // IN
            let currsc: SoundCard | null = this.activeInputSoundCard
            let ioDesc: string = "Input"
            if (currsc != null) {
                // we have an existing active SoundCard...
                const currname: string = currsc.name
                // is it in the list?
                const scWithNameInList = this.soundCards.find(
                    (s) => s.name == currname
                )
                if (scWithNameInList) {
                    // it IS in the list
                    // need to check the saved name matches?
                    if (workingDataRef.audioInCardName != currname) {
                        // saved name doesnt match or is undefined - update it with currname
                        workingDataRef.audioInCardName = currname
                        settingsNeedSaving = true // & flag need to save settings
                        // finished
                    } else {
                        fNOP() // everything is OK...
                        // finished
                    }
                } else {
                    // curr sc name not in list - so
                    // is there a saved name & is that in the list?
                    const wdrName: string | undefined =
                        workingDataRef.audioInCardName
                    if (wdrName && wdrName.length) {
                        // there IS a saved name...
                        const wdrSoundcard = this.soundCards.find(
                            (s) => s.name == wdrName
                        )
                        if (wdrSoundcard) {
                            // soundcard with saved name exists so use it
                            this.activeInputSoundCard = wdrSoundcard
                            toNotify = true
                            // saved name already OK
                            // finished
                        } else {
                            // soundcard with saved name doesnt exist
                            this.activeInputSoundCard = null
                            workingDataRef.audioInCardName = undefined
                            settingsNeedSaving = true
                            toNotify = true
                            // finished
                        }
                    } else {
                        // not in list and no saved name
                        // get rif of active Soundcard
                        this.activeInputSoundCard = null
                        toNotify = true
                        // finished
                    }
                }
            } else {
                // no active SoundCard...
                // is there a saved name & is that in the list?
                const wdrName: string | undefined =
                    workingDataRef.audioInCardName
                if (wdrName && wdrName.length) {
                    // there IS a saved name...
                    const wdrSoundcard = this.soundCards.find(
                        (s) => s.name == wdrName
                    )
                    if (wdrSoundcard) {
                        // soundcard with saved name exists so use it
                        this.activeInputSoundCard = wdrSoundcard
                        toNotify = true
                        // saved name already OK
                        // finished
                    } else {
                        // a soundcard with saved name doesnt exist in the list
                        // zap the saved name
                        workingDataRef.audioInCardName = undefined
                        settingsNeedSaving = true
                        // finished
                    }
                } else {
                    // no saved name so can't do anything...
                    // finished
                }
            }

            // OUT
            currsc = this.activeOutputSoundCard
            ioDesc = "Output"
            if (currsc != null) {
                // we have an existing active SoundCard...
                const currname: string = currsc.name
                // is it in the list?
                const scWithNameInList = this.soundCards.find(
                    (s) => s.name == currname
                )
                if (scWithNameInList) {
                    // it IS in the list
                    // need to check the saved name matches?
                    if (workingDataRef.audioOutCardName != currname) {
                        // saved name doesnt match or is undefined - update it with currname
                        workingDataRef.audioOutCardName = currname
                        settingsNeedSaving = true // & flag need to save settings
                        // finished
                    } else {
                        fNOP() // everything is OK...
                        // finished
                    }
                } else {
                    // curr sc name not in list - so
                    // is there a saved name & is that in the list?
                    const wdrName: string | undefined =
                        workingDataRef.audioOutCardName
                    if (wdrName && wdrName.length) {
                        // there IS a saved name...
                        const wdrSoundcard = this.soundCards.find(
                            (s) => s.name == wdrName
                        )
                        if (wdrSoundcard) {
                            // soundcard with saved name exists so use it
                            this.activeOutputSoundCard = wdrSoundcard
                            toNotify = true
                            // saved name already OK
                            // finished
                        } else {
                            // soundcard with saved name doesnt exist
                            this.activeOutputSoundCard = null
                            workingDataRef.audioOutCardName = undefined
                            settingsNeedSaving = true
                            toNotify = true
                            // finished
                        }
                    } else {
                        // not in list and no saved name
                        // get rif of active Soundcard
                        this.activeOutputSoundCard = null
                        toNotify = true
                        // finished
                    }
                }
            } else {
                // no active SoundCard...
                // is there a saved name & is that in the list?
                const wdrName: string | undefined =
                    workingDataRef.audioOutCardName
                if (wdrName && wdrName.length) {
                    // there IS a saved name...
                    const wdrSoundcard = this.soundCards.find(
                        (s) => s.name == wdrName
                    )
                    if (wdrSoundcard) {
                        // soundcard with saved name exists so use it
                        this.activeOutputSoundCard = wdrSoundcard
                        toNotify = true
                        // saved name already OK
                        // finished
                    } else {
                        // a soundcard with saved name doesnt exist in the list
                        // zap the saved name
                        workingDataRef.audioOutCardName = undefined
                        settingsNeedSaving = true
                        // finished
                    }
                } else {
                    // no saved name so can't do anything...
                    // finished
                }
            }

            if (toNotify) {
                // if use activeIOSC setter instead of raw _activeIOSC val it notifies anyway
                // so shouldne need extra notify...
                // this.notify(DeviceChangeEventType.ActiveSoundCard);
            }

            //TODO - may want video to start automatically?
            // if(videoDeviceIndex !== undefined) {
            //     if(this._videoDevices.length) {
            //         if(videoDeviceIndex > -1 && videoDeviceIndex <= this._videoDevices.length) {
            //             this.activeVideoDevice = this._videoDevices[videoDeviceIndex - 1];
            //         }
            //     }
            // }

            const currscin = this.activeInputSoundCard
            const currscout = this.activeOutputSoundCard

            if (currscin !== null && currscout !== null) {
                if (workingDataRef.audioInCardName != currscin.name) {
                    workingDataRef.audioInCardName = currscin.name
                    settingsNeedSaving = true
                }
                if (workingDataRef.audioOutCardName != currscout.name) {
                    workingDataRef.audioOutCardName = currscout.name
                    settingsNeedSaving = true
                }
                //var selIndex: number = this.deviceDataStore.inChansSelectIndex;
                const selIndex = Number(workingDataRef.inChannelsIndex)
                const audioChannelIndexCode =
                    inputChannelCountPossibilityIndices[selIndex]
                // const audioChannelIndexCodeS : string = audioChannelIndexCode.toString();
                // if(workingDataRef.inChannelsIndex != audioChannelIndexCodeS) {
                //     workingDataRef.inChannelsIndex = audioChannelIndexCodeS;
                //     settingsNeedSaving = true;
                // }
                // const playbackChannels : number = this.deviceDataStore.outChans;
                const playbackChannels: number = Number(
                    workingDataRef.outChannelsActual
                )
                // const playbackChannelsS : string = playbackChannels.toString();
                // if(workingDataRef.outChannelsActual != playbackChannelsS) {
                //     workingDataRef.outChannelsActual = playbackChannelsS;
                //     settingsNeedSaving = true;
                // }

                // !!! for now ignore other Select items..

                if (playbackChannels > 0) {
                    const frameSize: number =
                        latencies[parseInt(workingDataRef.frameSizeIndex)]
                    const sampleRateIndex: number = parseInt(
                        this.deviceDataStore.advancedSettings.sampleRateIndex
                    )
                    const reqSampleRate: number =
                        SampleRateValues[sampleRateIndex]

                    // need to check whether sample rate IS supportred by current i/o audio device(s)...
                    const sampleRate = checkAndLimitRequestedSampleRateIfNecc(
                        reqSampleRate,
                        currscin,
                        currscout
                    )
                    if (sampleRate != reqSampleRate) {
                        this.logger.warn(
                            `reqSampleRate ${reqSampleRate} was illegal - setting to ${sampleRate}`
                        )
                    }

                    const frameSizeSendIndex: number = parseInt(
                        this.deviceDataStore.advancedSettings
                            .networkBufferSizeIndex
                    )
                    const reqFrameSizeSend: number =
                        NetworkBufferSizes[frameSizeSendIndex]
                    let frameSizeSend: number = reqFrameSizeSend
                    if (reqFrameSizeSend < frameSize) {
                        this.logger.warn(
                            `frameSizeSend ${reqFrameSizeSend} was < frameSize ${frameSize} - setting to ${frameSize}`
                        )
                        frameSizeSend = frameSize
                    }

                    Agent.send(
                        new StartAudioMessageWithMostParams(
                            currscin.index.toString(),
                            currscout.index.toString(),
                            audioChannelIndexCode.toString(),
                            playbackChannels.toString(), // actual n playback cbannels 1:N
                            frameSize.toString(),
                            sampleRate.toString(),
                            frameSizeSend.toString()
                        )
                    )
                    this.resendAllOtherSettingsExplicitly()
                    this._initialisationState =
                        AudioInitialisationSequenceStates.AISS_Ready
                } else {
                    fNOP() // no playback chans - cant proceed
                }
            } else {
                fNOP() // not got both valid i/o cards set up yet...
            }

            if (settingsNeedSaving) {
                sendSaveSettings(Agent, workingDataRef)
            } else {
                fNOP() // already OK
            }
        } // eo AISS3

        if (
            this._initialisationState ==
                AudioInitialisationSequenceStates.AISS_Ready ||
            this._initialisationState ==
                AudioInitialisationSequenceStates.AISS_Confirmed
        ) {
            if (
                this.activeInputSoundCard == null ||
                this.activeOutputSoundCard == null
            ) {
                this._initialisationState =
                    AudioInitialisationSequenceStates.AISS_Uninitialised
                this._soundCards = []
                this._videoDevices = []
                const workingDataRef = this.getSavedSettings()
                if (this.activeInputSoundCard == null) {
                    workingDataRef.audioInCardName = undefined
                }
                if (this.activeOutputSoundCard == null) {
                    workingDataRef.audioOutCardName = undefined
                }
                this.saveSavedSettings(workingDataRef)
                sendSaveSettings(Agent, workingDataRef)
                this.sequence_Start(true)
            }
        }
        // THIS IS NOT ADEQUATE: need better scheme
        // and on restart GUI, may need to re-send some messages eg spatial etc sels
        // so BA 'matches' state of the controls!
    }

    public addDeviceChangeListener(listener: DeviceChangeListener) {
        if (this._deviceChangeListeners.indexOf(listener) == -1) {
            this._deviceChangeListeners.push(listener)
        }
    }

    public removeDeviceChangeListener(
        listener: DeviceChangeListener
    ): DeviceChangeListener | null {
        const x = this._deviceChangeListeners.indexOf(listener)
        if (x > -1) {
            return this._deviceChangeListeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    agentMessageListener: MessageEventListener = {
        handleMessageEvent(
            event: MessageEvent,
            eventData: any | null | undefined
        ) {
            if (!eventData || !eventData.type) return
            switch (eventData.type) {
                case "setAudioDeviceInfo":
                    {
                        const debdata: {
                            index: number
                            name: string
                            inputChannels: number
                            outputChannels: number
                        } = eventData // test it can do this ??? if so can we push(data) instead?
                    }

                    // ??? or pre check name & only push if valid?
                    LocalDevice.soundCards.push({
                        index: eventData.audioCount || 0,
                        name: eventData.audioName ?? "no audio",
                        inputChannels: eventData.inputChannels || 0,
                        outputChannels: eventData.outputChannels || 0,
                    })
                    LocalDevice.logger.info(
                        `Added soundcard index ${eventData.audioCount} ${eventData.audioName}`
                    )
                    break

                case "setNICOptions":
                    {
                        const debNumPossibleIFs = 6
                        const ifs: Array<string> = []
                        let ifindex = 0
                        ifs[ifindex++] = eventData.IF1 || ""
                        ifs[ifindex++] = eventData.IF2 || ""
                        ifs[ifindex++] = eventData.IF3 || ""
                        ifs[ifindex++] = eventData.IF4 || ""
                        ifs[ifindex++] = eventData.IF5 || ""
                        ifs[ifindex++] = eventData.IF6 || ""
                        LocalDevice._deviceData.saveNICData(
                            ifs.filter((theIF) => theIF != "NOT PRESENT")
                        )
                        LocalDevice.logger.info(
                            `Added NICs ${LocalDevice._deviceData.getaNICs()}`
                        )

                        // at this point we will have had ALL incoming setAudioDeviceInfo messages
                        // so its OK to update the GUI wrt soundcards & videoDevices ??? to suss
                        // may not be correct to do this - ??? sometimes get doubly populated list?
                        LocalDevice.notify(DeviceChangeEventType.SoundCards)
                        LocalDevice.notify(DeviceChangeEventType.VideoDevices)
                        if (
                            LocalDevice._initialisationState ==
                            AudioInitialisationSequenceStates.AISS_1
                        ) {
                            LocalDevice._initialisationState =
                                AudioInitialisationSequenceStates.AISS_2
                        }
                    }
                    break

                case "setVideoDeviceInfo":
                    if (
                        eventData.videoName &&
                        eventData.videoName != "no video"
                    ) {
                        LocalDevice._videoDevices.push({
                            index: eventData.videoCount || 0,
                            //name: eventData.videoName ?? "no video"
                            name: eventData.videoName,
                        })
                        LocalDevice.logger.info(
                            `Added videocard index ${eventData.videoCount} ${eventData.videoName}`
                        )
                    }
                    break

                case "setMIDIInputDeviceInfo":
                    LocalDevice.logger.info(
                        `Found Midi Input Device ${eventData.inputMIDIName}`
                    )
                    break
                case "setMIDIOutputDeviceInfo":
                    LocalDevice.logger.info(
                        `Found Midi Output Device ${eventData.outputMIDIName}`
                    )
                    break

                case "applySettings":
                    // implicitly < 240116 of BonzaApp but also incorrect sequencing:
                    // eg this message should NOT happen because we request a settingsDataV240124 response???
                    // alert("Version of BonzaApp is old, some things may not work");
                    // const sdo :settingsDataV240124 = eventData;  // may need to convert??? CHECK THIS
                    // this.workingData.saveSavedSettings(sdo);
                    alert(
                        "'applySettings' received - but this message should never be received!"
                    )
                    // this.initialisationState = InitialisationSequenceStates.ISS_Error;
                    break

                case "applySettingsV240124":
                    {
                        const sdjson: string = eventData.settingsDataV240124JSON
                        if (sdjson != undefined) {
                            const MIN_LEN = 10 // arb, if empty file contains \n,\0,EOF
                            if (sdjson.length < MIN_LEN) {
                                // probably empty save file on BA ... ignore
                            } else {
                                const sdn: any = JSON.parse(
                                    eventData.settingsDataV240124JSON
                                )
                                if (
                                    sdn &&
                                    sdn.userName &&
                                    sdn.userName.length &&
                                    BonzaService.user &&
                                    sdn.userName === BonzaService.user.name
                                ) {
                                    // 240530 remove OOD items...
                                    if (
                                        Object.prototype.hasOwnProperty.call(
                                            sdn,
                                            "audioInCardIndex"
                                        )
                                    )
                                        delete sdn.audioInCardIndex
                                    if (
                                        Object.prototype.hasOwnProperty.call(
                                            sdn,
                                            "audioOutCardIndex"
                                        )
                                    )
                                        delete sdn.audioOutCardIndex
                                    if (
                                        Object.prototype.hasOwnProperty.call(
                                            sdn,
                                            "faderPos"
                                        ) ||
                                        "faderPos" in sdn
                                    )
                                        delete sdn.faderPos
                                    LocalDevice._deviceData.saveSavedSettings(
                                        sdn
                                    )
                                } else {
                                    // name doesnt match or duff data - dont save it!
                                    LocalDevice.logger.warn(
                                        `Device: applySettingsV240124 from BA was rejected ${JSON.stringify(sdn)}`
                                    )
                                    return
                                }
                                LocalDevice.logger.info(
                                    `Device applySettingsV240124 from BA was accepted ${JSON.stringify(sdn)}`
                                )
                                // but we cant update soundcard selectors yet because the soundcard arrays will have been
                                // cleared in sequenceStart ... will have to wait until a later step in the sequence..
                                const debstate =
                                    LocalDevice._initialisationState
                                fNOP()
                            }
                        }
                    }
                    break

                case "getUUID":
                    {
                        const uuid: string | null = eventData.UUID
                        LocalDevice.logger.info(`UUID is ${uuid}`)
                    }
                    break

                case "standalone":
                    {
                        let ok: boolean = true
                        const verN: number = Number(eventData.version).valueOf()
                        if (eventData.version.length == 0 || isNaN(verN)) {
                            ok = false
                        } else if (verN >= 230616) {
                            // backwards compat main version
                            fNOP() // not necc OK - V1 methods may not be supported...
                            if (eventData.BonzaVersion != undefined) {
                                LocalDevice._deviceData.currentBAVersionN =
                                    eventData.BonzaVersion
                                if (eventData.BonzaVersion >= 240124) {
                                    fNOP() // can use all 240124 or earlier methods
                                }
                                // sendObject["BonzaVersion"] = 240501;
                                if (eventData.BonzaVersion >= 240501) {
                                    const debUUID = eventData.UUID
                                    LocalDevice._deviceData.UUID = debUUID
                                    fNOP() // can use all 240501 or earlier methods
                                } else {
                                    // old BonzaVersion
                                    ok = false
                                }
                            } else {
                                // no BonzaVersion
                                ok = false
                            }
                        }
                        if (!ok) {
                            const allowWrongVersionAnyway: boolean = true
                            alert(
                                `Wrong Version of BonzaApp ${eventData.version}! should be ${LocalDevice._deviceData.expectedBAVersionN} or later`
                            )
                            if (!allowWrongVersionAnyway) {
                                LocalDevice._initialisationState =
                                    AudioInitialisationSequenceStates.AISS_Error
                            }
                        }
                    }
                    break

                case "crashIndicator":
                case "tellPort":
                    // Bonza App has not crashed because we got this message!
                    LocalDevice.crashIndicatedIfGetsTooHighCounter = 0 // reset crash counter
                    break

                // others (unhandled so far) here
                case "soundCardStatus":
                    break

                case "setLocalSoundLevel":
                case "setLocalSoundLevelV1":
                    // Now w can if necc confirm audio is running by fact we are getting these messages..
                    if (
                        LocalDevice.initState ==
                        AudioInitialisationSequenceStates.AISS_Ready
                    ) {
                        LocalDevice.initState =
                            AudioInitialisationSequenceStates.AISS_Confirmed
                    } else if (
                        LocalDevice.initState !=
                        AudioInitialisationSequenceStates.AISS_Confirmed
                    ) {
                        LocalDevice.logger.warn(
                            `Device:773 Poss AISS sequence problem`
                        )
                    }
                    break

                // others (handled elsewhere) here
                case "setRemoteSoundLevel":
                case "sendVideoImage":
                case "sendRemoteVideoImage":
                case "tellLatency":
                case "tellDropout":
                case "streamIsGone":
                case "streamIsHere":
                case "setJitterBuffer":
                case "traceroute":
                    {
                        const debMsgType: string = eventData.type
                    }
                    break

                default:
                    LocalDevice.logger.todo(
                        `Unhandled incoming message ${eventData.type}: ${eventData}`
                    )
                    break
            }
        },
    }

    agentEventListener: SocketEventListener = {
        handleSocketEvent(event: SocketEvent) {
            if (event.type == "open") {
                LocalDevice.logger.info(
                    `Agent now open : initialisationState sequence state was : ${LocalDevice._initialisationState}`
                )
                // LocalDevice.sequence_Start(false);
                setTimeout(() => {
                    LocalDevice.sequence_Start(false)
                }, 1000)
            }
            // LOGIC FOR CONNECT HERE....
        },
    }

    private notify(eventType: DeviceChangeEventType) {
        this._deviceChangeListeners.forEach((listener) => {
            listener.handleDeviceChange(this, eventType)
        })
    }

    private resendAllOtherSettingsExplicitly() {
        // startAudio does soundcards in & out, their num channels and latency.
        // rest* need doing one by one, just in case Bonza engine been restarted
        // (which would default all of these internally - though doesnt lose them
        // if just power-off on via the button - but safer to resend anyway...)
        // *except for video colour & resolution - which get re-sent when camera is turned on

        const workingDataRef: settingsDataV240124 = this.getSavedSettings()

        const spatialLocationIndex: number = Number(
            workingDataRef.spatialLocationIndex
        )
        if (
            spatialLocationIndex >= 0 &&
            spatialLocationIndex < spatialLocations.length
        ) {
            const locnString: string = spatialLocations[spatialLocationIndex]
            Agent.send(new spatialLocationMessage(locnString))
        } else {
            fNOP()
        }

        const diffuseIRLevelIndex: number = Number(
            workingDataRef.diffuseIRLevelIndex
        )
        Agent.send(new diffuseIRDepthMessageV240124(diffuseIRLevelIndex))

        const oZoneIndex: number = Number(workingDataRef.oZoneIndex)
        Agent.send(new setOzoneMessage(ozoneMessages[oZoneIndex]))

        const directOnOff: string = workingDataRef.directOnOff
        Agent.send(
            new localDirectOnOffMessageV240124(
                directOnOff === "1" ? true : false
            )
        )
    }
}

export function sendSaveSettings(
    Agentref: AgentService,
    workingDataRef: settingsDataV240124
) {
    Agentref.send(new saveSettingsToFileV240124Message(workingDataRef))
}

function checkAndLimitRequestedSampleRateIfNecc(
    sampleRate: number,
    currscin: SoundCard,
    currscout: SoundCard
): number {
    // MacBook Air doesnt go above 96000;
    if (
        currscin.name == "MacBook Air Microphone" ||
        currscout.name == "MacBook Air Speakers"
    ) {
        if (sampleRate > 96000) {
            sampleRate = 96000
        }
    }
    /* others TODO */

    return sampleRate
}
