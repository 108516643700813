import { ComponentProps, useState, DragEvent } from "react"
import LoggerService from "@/services/LoggerService"

interface KnobProps extends ComponentProps<"div"> {
    size?: number
    tickCount?: number
    degrees?: number
    min?: number
    max?: number
    value?: number
    defaultValue?: number
    listener?: (value: number) => void
}

export default function Knob(props: KnobProps) {
    const logger: LoggerService = new LoggerService(`Knob`) // level 0 disables for now...

    const size = props.size ?? 90
    const tickCount = props.tickCount ?? 10
    const min = props.min ?? 0
    const max = props.max ?? 100

    const fullAngle = props.degrees ?? 270
    const startAngle = (360 - fullAngle) / 2
    const endAngle = startAngle + fullAngle
    const margin = size * 0.15
    const tickSize = margin + size / 2

    const getDeg = (cX: number, cY: number, pts: { x: number; y: number }) => {
        const x = cX - pts.x
        const y = cY - pts.y
        let deg = (Math.atan(y / x) * 180) / Math.PI
        if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
            deg += 90
        } else {
            deg += 270
        }
        return Math.min(Math.max(startAngle, deg), endAngle)
    }

    const convertRange = (
        oldMin: number,
        oldMax: number,
        newMin: number,
        newMax: number,
        oldValue: number
    ) => {
        return Math.max(
            min,
            ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) +
                newMin
        )
    }

    const [value, setValue] = useState(props.value ?? props.defaultValue ?? 0)
    const zeroAngle = Math.floor(
        convertRange(min, max, startAngle, endAngle, 0)
    )
    const zeroAlways = zeroAngle > startAngle

    let currentDeg = Math.floor(
        convertRange(min, max, startAngle, endAngle, value)
    )

    const [deg, setDeg] = useState(currentDeg)

    const styles = {
        knob: {
            width: size,
            height: size,
        },
        outer: {
            width: size,
            height: size,
            margin: margin,
            borderRadius: "50%",
            border: "1px solid #000",
            borderBottom: "5px solid #000",
            boxShadow:
                "0 5px 15px 2px #202126, 0 0 5px 3px #202126, 0 0 0 16px #202126",
            backgroundImage: `radial-gradient(circle at bottom left, #202126, #202126)`,
            // backgroundImage: `radial-gradient(100% 70%, hsl(210, ${currentDeg}%, ${currentDeg / 5}%), hsl(${Math.random() * 100}, 20%, ${currentDeg / 36}%))`,
            zIndex: 1,
        },
        inner: {
            width: size,
            height: size,
            borderRadius: "50%",
            transform: `rotate(${deg}deg)`,
        },
        grip: {
            width: "2%",
            height: "25%",
            bottom: "2%",
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "0%",
            backgroundColor: "#455DFF",
            boxShadow: "0 0 3px 1px black",
        },
        tick: {
            backgroundColor: "black",
            width: "3px",
            borderRadius: "1px",
            transition: "box-shadow 0.2s",
            height: tickSize + 6,
            left: tickSize - 1,
            top: tickSize + 2,
        },
    }

    // 240801 Sai: Finally the knob is working!!!
    const KnobDClickHandler = () => {
        const defaultValue = props.defaultValue ?? 0
        const defaultDeg = Math.floor(
            convertRange(min, max, startAngle, endAngle, defaultValue)
        )
        //logger.log(`Value is ${newValue}`); //  Here's where to pass to handler
        if (props.listener) {
            props.listener(defaultValue)
        }
        setDeg(defaultDeg)
    }

    const ticksMeta = []
    if (tickCount > 0) {
        const inc = fullAngle / tickCount
        for (let d = startAngle; d <= endAngle; d += inc) {
            ticksMeta.push({
                deg: d,
                style: {
                    ...styles.tick,
                    transform: `rotate(${d}deg)`,
                    marginTop: "-2px",
                    transformOrigin: "top",
                },
            })
        }
    }

    const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const knob = e.target as HTMLDivElement
        const rect = knob.getBoundingClientRect()
        const pts = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        }

        const handleMove = (e: MouseEvent) => {
            currentDeg = getDeg(e.clientX, e.clientY, pts)
            if (currentDeg === startAngle) currentDeg--
            const newValue = Math.floor(
                convertRange(startAngle, endAngle, min, max, currentDeg)
            )
            //logger.log(`Value is ${newValue}`); //  Here's where to pass to handler
            if (props.listener) {
                props.listener(newValue)
            }
            setDeg(currentDeg)
        }
        document.addEventListener("mousemove", handleMove)
        document.addEventListener("mouseup", (e) => {
            document.removeEventListener("mousemove", handleMove)
        })
    }

    const activeTick = (tickDeg: number) => {
        return (
            (tickDeg == zeroAngle && zeroAlways) ||
            (tickDeg < zeroAngle && tickDeg >= deg) ||
            (tickDeg >= zeroAngle && tickDeg <= deg)
        )
    }

    return (
        <div
            className={`
                ${props.className}

                relative flex KNOB
            `}
            draggable="true"
            style={{
                ...props.style,
                ...styles.knob,
            }}
            onDoubleClick={KnobDClickHandler} // 240801 Sai: Finally the knob is working!!!
            onDragStart={handleDragStart}
        >
            <div className={`absolute TICKS`}>
                {ticksMeta.map((tickMeta, i) => {
                    return (
                        <div
                            key={i}
                            className="absolute"
                            style={{
                                ...tickMeta.style,

                                boxShadow: activeTick(tickMeta.deg)
                                    ? "inset 0 0 5px 2px #455DFF, 0 0 0 1px #369"
                                    : "inset 0 0 0 0 #1c4355",
                            }}
                        />
                    )
                })}
            </div>
            <div
                className={`OUTER`}
                style={{
                    ...styles.outer,
                }}
            >
                <div
                    className={`INNER`}
                    style={{
                        ...styles.inner,
                    }}
                >
                    <div
                        className={`absolute GRIP`}
                        style={{
                            ...styles.grip,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    )
}
