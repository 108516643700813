import { ExpandMore as ExpandIcon } from "@mui/icons-material"

export const StandardSelectProps = {
    className: "h-8 bg-bonza-dark rounded-[60px]",
    sx: {
        color: "#FFFFFF",
        "& .MuiSvgIcon-root": { color: "#455dff" },
        "& .Mui-disabled": {
            WebkitTextFillColor: "rgba(255, 255, 255, 0.6)",
            color: "rgba(255, 255, 255, 0.6)",
            "& .MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.6)" },
        },
    },
    inputProps: {
        MenuProps: {
            MenuListProps: {
                sx: {
                    backgroundColor: "#131619",
                    color: "#FFFFFF",
                    "& .MuiMenuItem-root": {
                        "&:hover": {
                            backgroundColor: "#202126",
                        },
                        "&.Mui-selected": {},
                    },
                    "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(255, 255, 255, 0.6)",
                        color: "rgba(255, 255, 255, 0.6)",
                        "& .MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.6)" },
                    },
                },
            },
        },
    },
    IconComponent: ExpandIcon,
}
