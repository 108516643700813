interface ServiceLog {
    [type: string]: {
        count: number
        subs?: ServiceLog
    }
}

export class ServiceLogHelper {
    private _name: string
    private _received: ServiceLog = {}
    private _sent: ServiceLog = {}

    constructor(name: string) {
        this._name = name
    }

    get name() {
        return this._name
    }

    received(type: string, subtype?: string) {
        if (!this._received[type]) {
            this._received[type] = { count: 1 }
        } else {
            this._received[type].count++
        }

        if (subtype) {
            if (!this._received[type].subs) {
                this._received[type].subs = {}
            }
            // @ts-ignore
            if (!this._received[type].subs[subtype]) {
                // @ts-ignore
                this._received[type].subs[subtype] = { count: 1 }
            } else {
                // @ts-ignore
                this._received[type].subs[subtype].count++
            }
        }
    }

    getReceived() {
        return this._received
    }

    sent(type: string) {
        if (!this._sent[type]) {
            this._sent[type] = { count: 1 }
        } else {
            this._sent[type].count++
        }
    }

    getSent() {
        return this._sent
    }
}
